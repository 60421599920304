import { TokenCardTransactionType } from "../constants/Types";

const TokenCardTransaction = ({
  tokenIcon,
  tokenName,
  tokenSymbol,
  clickedTokenData,
  tokenBalance,
}: TokenCardTransactionType) => {
  const renderData = () => (
    <div
      onClick={() => {
        if (tokenBalance === null) {
          return;
        }

        clickedTokenData(tokenBalance);
      }}
      className={`mx-auto mb-2`}
    >
      <div
        className={`flex gap-1 flex-row items-center ${
          tokenBalance === null
            ? "opacity-60"
            : "hover:bg-card-bg2  hover:bg-opacity-50"
        }   px-4 pt-3 pb-4 rounded-lg  `}
      >
        <div>
          <img
            src={tokenIcon}
            alt="token Logo"
            className=" w-9 rounded-full object-cover mr-4 border-2"
          />
        </div>
        <div className="w-full flex justify-between items-center">
          <div>
            <p className="text-sm select-none">{tokenName}</p>
            <p className="text-xs font-semibold overflow-hidden text-gray-500 select-none">
              {tokenSymbol}
            </p>
          </div>
          <div className="items-end text-sm select-none">
            <p title="current Balance">
              {Number(Number(tokenBalance).toFixed(5))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return renderData();
};

export default TokenCardTransaction;
