import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import Moralis from "moralis";
import localforage from "localforage";
import { supportedChainsState } from "../../../../../state/SupportedChainsState";
import TransactionHistory from "../../../TransactionHistory";
import {
  log,
  formatDateToWords,
  getItemFromStorage,
} from "../../../../../utils/helper";
import Loader from "../../../../common/Loader";
import {
  AllPreviousTransactions,
  TransactionData,
} from "../../../../../constants/Types";
import activity from "../../../../../assets/activity.svg";
import { GAS_FEE_TYPE, STORAGE_KEYS } from "../../../../../constants/Enums";
import useWallet from "../../../../../lib/store/hooks/useWallet";

const Activity = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [supportedChains] = useRecoilState(supportedChainsState);
  const [allPreviousTransactions, setAllPreviousTransactions] =
    useState<AllPreviousTransactions>({});
  const {
    smartAccountAddress: smartAccountAddressInRedux,
    chainData,
    userSettings,
    eoaAddress,
  } = useWallet();
  const smartAccountAddressInStorage = getItemFromStorage(
    STORAGE_KEYS.SMART_ACCOUNT,
  );
  const addressInStorage = getItemFromStorage(STORAGE_KEYS.SIGNER);
  const chianIdInStorage = getItemFromStorage(STORAGE_KEYS.NETWORK);

  const chainId = chainData?.chainId || chianIdInStorage;
  const userAccount = userSettings?.isEoaSelected
    ? eoaAddress || addressInStorage
    : smartAccountAddressInRedux || smartAccountAddressInStorage;

  function formatTimestamp(timestamp: string) {
    const date = new Date(timestamp);
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }

  const previousTransactionsHistory = async () => {
    const previousTransactions: AllPreviousTransactions =
      (await localforage.getItem(STORAGE_KEYS.PREVIOUS_TRANSACTIONS)) || {};

    try {
      previousTransactions[userAccount] =
        previousTransactions[userAccount] || {};
      previousTransactions[userAccount][chainId] =
        previousTransactions[userAccount][chainId] || {};

      const lastDate =
        Object.keys(previousTransactions[userAccount][chainId])[0] || "";

      const explorerUrl: string = (
        supportedChains.find((chain) => chain.chainId === chainId)
          ?.explorerUrl || ""
      ).concat("/tx/");

      const response =
        await Moralis.EvmApi.transaction.getWalletTransactionsVerbose({
          chain: chainId,
          fromDate: lastDate && lastDate,
          address: userAccount,
        });

      const resultArray = response.raw.result;

      resultArray.reverse().forEach((transaction) => {
        const dateOnly = transaction.block_timestamp.substring(0, 10);
        const transactionData: TransactionData = {
          date: dateOnly,
          time: formatTimestamp(transaction.block_timestamp),
          transaction_id: transaction.hash,
          from_address: transaction.from_address,
          to_address: transaction.to_address,
          nonce: transaction.nonce,
          amount: transaction.value,
          gas_limit: transaction.gas || "",
          gas_price: transaction.gas_price,
          label: transaction.decoded_call?.label
            ? transaction.decoded_call?.label.replace("Send ", "Sent")
            : "Received",
          status: transaction.receipt_status,
          explorer: explorerUrl,
          isGasless: GAS_FEE_TYPE.GASLESS,
        };

        previousTransactions[userAccount][chainId][dateOnly] =
          previousTransactions[userAccount][chainId][dateOnly] || [];

        const existingTransaction = previousTransactions[userAccount][chainId][
          dateOnly
        ].find(
          (existingTransactionInLocal: TransactionData) =>
            existingTransactionInLocal.transaction_id ===
            transactionData.transaction_id,
        );

        if (!existingTransaction) {
          const sortedTransactions = [
            transactionData,
            ...previousTransactions[userAccount][chainId][dateOnly],
          ].sort((a, b) => {
            const [aHour, aMinute, aSecond] = a.time.split(":").map(Number);
            const [bHour, bMinute, bSecond] = b.time.split(":").map(Number);

            if (aHour === bHour) {
              if (aMinute === bMinute) {
                return bSecond - aSecond;
              }

              return bMinute - aMinute;
            }

            return bHour - aHour;
          });

          previousTransactions[userAccount][chainId][dateOnly] =
            sortedTransactions;
        }
      });

      await localforage.setItem(
        STORAGE_KEYS.PREVIOUS_TRANSACTIONS,
        previousTransactions,
      );
      setAllPreviousTransactions(previousTransactions);
    } catch (e) {
      log("Couldn't get previous transactions", e, "error");
      setAllPreviousTransactions(previousTransactions);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    previousTransactionsHistory();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="pt-0 pb-20 h-full max-h-full flex flex-col overflow-y-scroll ">
          {allPreviousTransactions[userAccount] &&
          allPreviousTransactions[userAccount][chainId] &&
          Object.keys(allPreviousTransactions[userAccount][chainId]).length >
            0 ? (
            Object.keys(allPreviousTransactions[userAccount][chainId])
              .reverse()
              .map((transactionDate, index) => (
                <div key={index} className="mb-4">
                  <p className=" border-b-2 border-card-bg mb-2">
                    {formatDateToWords(transactionDate)}
                  </p>
                  {allPreviousTransactions[userAccount][chainId][
                    transactionDate
                  ].map((transaction, transactionIndex) => (
                    <TransactionHistory
                      key={transactionIndex}
                      transaction={transaction}
                    />
                  ))}
                </div>
              ))
          ) : (
            <div className="flex flex-col gap-2 justify-center items-center  my-auto w-full h-fit px-5 py-6 rounded-2xl">
              <img
                className="h-14 rounded-xl"
                src={activity}
                alt="no nfts found"
              />
              <p className="text-lg text-gray-200 "> No Recent Activity</p>
              <p className="text-sm text-gray-500 font-medium text-center ">
                Your transactions and app activity will show up here when you
                start using WalletX
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Activity;
