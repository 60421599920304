import { ethers } from "ethers";

import { getUserSettingsData, log } from "../utils/helper";
import { UserSettingsType } from "../constants/Types";

let DappName = "";

async function WCEventHandler(
  {
    id,
    params,
    topic,
    type,
    wcInstance,
    smartAccountAddress,
    init,
    eoaAddress,
    chainID,
  }: {
    id: string;
    params: any;
    topic?: string;
    type: string;
    wcInstance: any;
    smartAccountAddress: string;
    init: any;
    eoaAddress: string;
    chainID: number;
  },
  navigation: any,
) {
  if (type === "session_proposal") {
    const {
      proposer: { metadata },
      optionalNamespaces,
    } = params;

    const dappName = metadata?.name;
    const dappIcon = metadata?.icons?.[0];
    const dappDescription = metadata?.description;
    const dappUrl = metadata?.url;
    const activeChain = optionalNamespaces?.eip155?.chains;

    DappName = dappName;

    navigation("/dashboard/wc/connect", {
      id,
      dappName,
      dappIcon,
      params,
      dappDescription,
      dappUrl,
      activeChain,
    });
  } else if (type === "session_request") {
    const {
      request: { method: reqMethod, params: reqParams },
    } = params;

    let response;

    const reqChainId = parseInt(params.chainId.split(":")[1], 10);

    const usersSettings: UserSettingsType[] = await getUserSettingsData();

    const userIndex = usersSettings.findIndex(
      (user: UserSettingsType) => user.address === eoaAddress,
    );

    const activeSmartWallet = usersSettings[userIndex];

    if (reqMethod === "eth_accounts" || reqMethod === "eth_requestAccounts") {
      response = { id, result: [smartAccountAddress], jsonrpc: "2.0" };

      await wcInstance.respondSessionRequest({ topic, response });
    } else if (reqMethod === "personal_sign" || reqMethod === "eth_sign") {
      if (reqChainId !== chainID) {
        await init({
          walletName: activeSmartWallet?.walletName || "",
          chainId: reqChainId,
        });

        const timer = setTimeout(async () => {
          log("Chain Switched", {});
        }, 2000);

        clearTimeout(timer);
      }

      let message = reqMethod === "personal_sign" ? reqParams[0] : reqParams[1];

      message = ethers.utils.toUtf8String(message);

      navigation("/dashboard/wc/signature", {
        id,
        message,
        topic,
        dappName: DappName,
      });
    } else if (reqMethod === "eth_sendTransaction") {
      if (reqChainId !== chainID) {
        await init({
          walletName: activeSmartWallet?.walletName || "",
          chainId: reqChainId,
        });

        const timer = setTimeout(async () => {
          log("Chain Switched", {});
        }, 2000);

        clearTimeout(timer);
      }

      const { to, value, data, from, gasLimit, gas } = reqParams[0];

      const transaction = { to, value, data, from, gasLimit, gas };

      navigation("/dashboard/wc/transaction", { id, transaction, topic });
    }

    return { id, result: response, jsonrpc: "2.0" };
  }
}

export default WCEventHandler;
