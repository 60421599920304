import { useLocation } from "react-router";

import { ArrowRight } from "react-feather";

import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

import PageTitle from "../../components/PageTitle";
import IllustrationLoader from "../../components/IllustrationLoader";

import { PAGE_TITLE_BUTTON_TYPE } from "../../constants/Enums";
import GasManagement from "../GasManagement";
import Web3AvatarComponent from "../Web3AvatarComponent";
import VerifyPassword from "../Modals/VerifyPassword";

const ApproveSwapAndBridgeTransactions = ({
  isLoading,
  transactionData,
  handleReject,
  transactionId,
  transactionInProcess,
  progress,
  transactionGas,
  setTransactionGas,
  partialTransaction,
  sendGaslessTransaction,
  sendBatchTransaction,
  nativeAssetGas,
  shouldShowAd,
  gasInUSD,
  isVerifyPassModalOpen,
  isVerifying,
  showPasswordError,
  setAdURL,
}: any) => {
  const { smartAccountAddress, userSettings } = useWalletConfig();

  const { state } = useLocation();
  const { quote } = state;

  const getShortDisplayString = (address: string | any[]) =>
    `${address.slice(0, 6)}...${address.slice(-4)}`;

  const getTotalGasFees = (gasCosts: { amountUSD: string }[]) =>
    gasCosts.reduce(
      (total, gasCost) => total + parseFloat(gasCost.amountUSD),
      0,
    );

  return (
    <>
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <IllustrationLoader height="1.8em" />
        </div>
      ) : (
        <div className="h-full w-full flex flex-col">
          <PageTitle
            title="Approve"
            leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
            leftButtonToolTip=""
            leftButtonToolTipCss=""
            leftDestinationRoute=""
            isLeftButtonDisabled={true}
            rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.X}
            rightButtonToolTip="Cancel"
            rightButtonToolTipCss="fixed top-0 right-5 bg-secondary-bg text-white border-black"
            isRightButtonDisabled={false}
            onRightButtonClick={() => handleReject("Cancelled")}
          />
          <div className="h-full w-full flex flex-col ">
            <div className="w-full rounded-xl p-3 flex gap-3 justify-center items-center ">
              <Web3AvatarComponent
                address={smartAccountAddress}
                className="w-10 h-10"
              />
              <div>
                <p>{userSettings?.walletName}</p>
                <p className="text-sm">
                  {getShortDisplayString(smartAccountAddress)}
                </p>
              </div>
            </div>
            <div className="w-full rounded-xl p-3 flex flex-col gap-5 justify-between ">
              <div className="flex justify-between items-center gap-4">
                <div className=" bg-card-bg2 w-full max-w-[40%] flex flex-col justify-center items-center py-4 rounded-xl">
                  <img
                    src={quote.action.fromToken.logoURI}
                    alt="fromChainLogo"
                    className="object-scale-down max-h-12 rounded-full"
                  />
                  <p className="text-md mt-5">
                    {(
                      Number(quote.estimate.fromAmount) /
                      10 ** quote.action.fromToken.decimals
                    ).toFixed(5)}
                  </p>
                  <p className="text-xs">
                    ${Number(quote.estimate.fromAmountUSD)}
                  </p>
                </div>
                <div className="grow flex justify-center items-center ">
                  <ArrowRight />
                </div>
                <div className=" bg-card-bg2 w-full max-w-[40%] flex flex-col items-center gap-1 py-4 rounded-xl">
                  <img
                    src={quote.action.toToken.logoURI}
                    alt="toChainLogo"
                    className="object-scale-down max-h-12 rounded-full"
                  />
                  <p className="text-md mt-5">
                    {(
                      Number(quote.estimate.toAmountMin) /
                      10 ** quote.action.toToken.decimals
                    ).toFixed(5)}
                  </p>
                  <p className="text-xs">
                    ${Number(quote.estimate.toAmountUSD)}
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col gap-3 px-2">
                <div className="w-full flex justify-between items-center">
                  <p className="text-sm">Minimum received</p>
                  <p className="text-sm">
                    {(
                      Number(quote.estimate.toAmountMin) /
                      10 ** quote.action.toToken.decimals
                    ).toFixed(5)}{" "}
                    {quote.action.toToken.symbol}
                  </p>
                </div>
                <div className="w-full flex justify-between items-center gap-2">
                  <p className="text-sm">
                    {quote.action.fromToken.symbol} Contract
                  </p>
                  <p className="text-sm">
                    {getShortDisplayString(quote.action.fromToken.address)}
                  </p>
                </div>
                {quote.action.toToken.address !==
                  "0x0000000000000000000000000000000000000000" && (
                  <div className="w-full flex justify-between items-center gap-2">
                    <p className="text-sm">
                      {quote.action.toToken.symbol} Contract
                    </p>
                    <p className="text-sm">
                      {getShortDisplayString(quote.action.toToken.address)}
                    </p>
                  </div>
                )}
                <div className="w-full flex justify-between items-center gap-2">
                  <p className="text-sm">Gas Fees</p>
                  <p className="text-sm">
                    ${getTotalGasFees(quote.estimate.gasCosts).toFixed(2)}
                  </p>
                </div>

                <div className="w-full flex justify-between items-center">
                  <p className="text-sm">Swapping via</p>
                  <div className="flex justify-center items-center gap-2">
                    <img
                      src={quote.toolDetails.logoURI}
                      alt="executionToolLogo"
                      className="h-5 w-5 rounded-full"
                    />
                    <p className="text-xs">{quote.toolDetails.name}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 w-full left-0 mb-2">
            <GasManagement
              transaction={{
                transactionId,
                transactionInProcess,
                progressBar: progress,
              }}
              selectedTokenForGas={transactionGas}
              setSelectedTokenForGas={setTransactionGas}
              isDappTransaction={true}
              transferDataList={[transactionData]}
              partialTransaction={partialTransaction}
              sendGaslessTransaction={sendGaslessTransaction}
              sendBatchTransaction={sendBatchTransaction}
              nativeAssetGas={nativeAssetGas}
              shouldShowAd={shouldShowAd}
              gasInUSD={gasInUSD}
              setAdURL={setAdURL}
            />
          </div>
        </div>
      )}
      <VerifyPassword
        isOpen={isVerifyPassModalOpen}
        isVerifying={isVerifying}
        showPasswordError={showPasswordError}
      />
    </>
  );
};

export default ApproveSwapAndBridgeTransactions;
