import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { SwapBridgeStateType } from "../constants/Types";

const { persistAtom } = recoilPersist();

// eslint-disable-next-line import/prefer-default-export
export const swapBridgeState = atom<SwapBridgeStateType[]>({
  key: "swapBridgeState",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
