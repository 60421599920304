import { useEffect, useState, useMemo } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import * as Config from "../../../config/env";
import useWallet from "../../../lib/store/hooks/useWallet";
import mainLogoX from "../../../assets/icons/main-logoX.png";
import wallet from "../../../assets/icons/walletFull.png";
import {
  DEVICE_AUTH_TYPE,
  MIXPANEL_KEY,
  TIME,
  WALLET_TYPE,
} from "../../../constants/Enums";
import { UserSettingsType } from "../../../constants/Types";
import {
  getUserSettingsData,
  getTime,
  log,
  setUserSettingsData,
  setSavedUsersData,
} from "../../../utils/helper";
import MixPanel from "../../../utils/MixPanel";
import illustrations from "../../../constants/RegistrationIconData";
import { createUserData } from "../../../utils/api";

const features = [
  {
    point1: "Welcome to",
    point2: "WalletX",
  },
  {
    point1: "Enjoy Gasless",
    point2: "Transaction",
  },
  {
    point1: "Earn Rewards on",
    point2: "User Dashboard",
  },
];
const fpPromise = FingerprintJS.load();

const Registration = () => {
  const navigate = useNavigate();
  const { init, login, smartAccountAddress, eoaAddress, isConnected } =
    useWallet();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get("referralCode") || undefined;

  const [walletName, setWalletName] = useState<string | null>(null);
  const [credId, setCredId] = useState<string | null>(null);
  const [defaultChainId] = useMemo(() => [Config.DEFAULT_NETWORK.CHAIN_ID], []);
  const [buttonTitle, setButtonTitle] = useState("Create new Wallet");

  const [logoClass, setLogoClass] = useState("w-[60%] mt-[20%] pl-5");
  const [showWalletLogo, setShowWalletLogo] = useState(false);
  const [showText, setShowText] = useState(false);
  const [runOneTime, setRunOneTime] = useState(0);
  const [featureIndex, setFeatureIndex] = useState(0);
  const [selectedIllustration, setSelectedIllustration] = useState<
    number | null
  >(null);

  const [shouldStartInterval, setShouldStartInterval] = useState(false);
  const [showClickBtn, setShowClickBtn] = useState(false);

  const saveUsersSettings = async (_deviceName: string, _credID: string) => {
    const time = getTime(TIME.HOUR_24);

    const newUserSettings: UserSettingsType = {
      address: eoaAddress ?? "",
      walletType: WALLET_TYPE.GENERATED,
      walletName: _deviceName,
      activeSmartAccountAddress: smartAccountAddress ?? "",
      id: _credID,
      mnemonic: null,
      accounts: [
        {
          address: smartAccountAddress ?? "",
          name: "Account 1",
          position: 1,
        },
      ],
      settings: {
        preferences: {
          theme: "default",
          currency: null,
        },
        security: {
          autoLockTimer: time.minutes,
          autoLockTimerLabel: time.time,
          authenticationType: DEVICE_AUTH_TYPE.NONE,
          password: null,
          biometrics: null,
        },
        recoveryPhrase: {
          seedPhraseBackedUp: false,
        },
        transactionSettings: {
          connect: DEVICE_AUTH_TYPE.NONE,
          signMsg: DEVICE_AUTH_TYPE.NONE,
          approveTransaction: DEVICE_AUTH_TYPE.NONE,
        },
        addressBook: [],
        developerMode: {
          showTestnet: false,
          turnOffAds: false,
        },
      },
    };

    try {
      const existingSettings = await getUserSettingsData();

      if (!existingSettings || !existingSettings.length) {
        await setUserSettingsData([newUserSettings]);
        log("Users settings saved to IndexedDB");
      } else {
        await setUserSettingsData([...existingSettings, newUserSettings]);
        log("Users settings added to existing settings in IndexedDB");
      }
    } catch (error) {
      log("Error saving users settings to IndexedDB:", error);
    }
  };

  const generateFingerPrint = useMemo(
    () => async () => {
      const fp = await fpPromise;
      const result = await fp.get();

      return result.visitorId;
    },
    [],
  );

  const createNewWallet = async () => {
    try {
      const fingerPrintID = await generateFingerPrint();
      const existingSettings = await getUserSettingsData();
      const uniqueId = `${fingerPrintID}-${existingSettings.length + 1}`;
      const name = `Wallet ${existingSettings.length + 1}`;

      setWalletName(name);
      setCredId(uniqueId);

      if (fingerPrintID) {
        setButtonTitle("Creating Wallet...");
        // Initialize wallet with the new uniqueId as credentialId
        await init({
          walletName: name,
          credentialId: uniqueId,
          chainId: defaultChainId,
        });
      }
    } catch (error) {
      toast.error("Error generating fingerprint", { id: "fingerprint-error" });
    }
  };

  useEffect(() => {
    async function initializeWallet() {
      if (smartAccountAddress && walletName && credId && eoaAddress) {
        setButtonTitle("Done");

        await saveUsersSettings(walletName, credId);

        try {
          await createUserData({
            eoaAddress,
            smartAccountAddress: smartAccountAddress && smartAccountAddress,
            userName: "Account 1",
            referralCode: referralCode && referralCode,
          });

          setSavedUsersData(eoaAddress);
        } catch (error) {
          log("Error creating user data:", error);
        }

        login();

        if (walletName) {
          MixPanel.identify(smartAccountAddress);
          MixPanel.register({
            name: walletName,
            walletAddress: smartAccountAddress,
          });
          MixPanel.track(MIXPANEL_KEY.Register_Successful, {
            name: walletName,
          });
        }

        navigate(`/welcome`, {
          state: {
            showWelcome: true,
          },
        });
      }
    }
    initializeWallet();
  }, [smartAccountAddress, eoaAddress, walletName, isConnected]);

  const getIllustrationClass = (index: number) => {
    if (selectedIllustration === null) {
      return index % 2 === 0 ? "pb-4" : "pt-4";
    }

    return "flex flex-col justify-center h-fit";
  };

  const handleIllustrationClick = (index: number) => {
    setSelectedIllustration(selectedIllustration === null ? index : null);
  };

  useEffect(() => {
    const logoAnimationSequence = [
      { delay: 1000, class: "w-fit max-h-[5rem] ml-[-0.2em] mt-[20%]" },
      {
        delay: 1700,
        class: "w-fit max-h-[5rem] ml-[-0.4em] mt-[20%] mr-[-13em]",
      },
      { delay: 1890, class: "w-fit max-h-[5rem] ml-[-0.2em] mt-[20%]" },
      { delay: 1900, showWalletLogo: true },
      { delay: 3500, showText: true, runOneTime: 1 },
    ];

    logoAnimationSequence.forEach(
      ({
        delay,
        class: logoClassUpdate,
        showWalletLogo: showLogo,
        showText: showTextUpdate,
        runOneTime: runOnce,
      }) => {
        const timeout = setTimeout(() => {
          if (logoClassUpdate) setLogoClass(logoClassUpdate);

          if (showLogo !== undefined) setShowWalletLogo(showLogo);

          if (showTextUpdate !== undefined) setShowText(showTextUpdate);

          if (runOnce !== undefined) setRunOneTime(runOnce);
        }, delay);

        return () => clearTimeout(timeout);
      },
    );
  }, []);

  useEffect(() => {
    let interval: any;

    if (shouldStartInterval) {
      interval = setInterval(() => {
        setFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
      }, 3000);
    } else {
      const timeout = setTimeout(() => {
        setShouldStartInterval(true);
      }, 4000);

      return () => clearTimeout(timeout);
    }

    return () => clearInterval(interval);
  }, [shouldStartInterval, features.length]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowClickBtn(true);
    }, 5000); // Show the div after 4 seconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let animationTimeout: any;

    if (showClickBtn) {
      animationTimeout = setTimeout(() => {
        setShowClickBtn(false);
      }, 3000); // Hide the div after 3 seconds (animation duration)
    }

    return () => clearTimeout(animationTimeout);
  }, [showClickBtn]);

  return (
    <div className="bg-primary-bg h-full w-full flex flex-col justify-between select-none">
      {selectedIllustration === null && (
        <motion.div
          className="flex justify-center items-center w-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {showWalletLogo && (
            <motion.img
              key="wallet-logo"
              src={wallet}
              alt="full"
              className="w-fit max-h-[4rem] p-3 pr-0 mt-[20%]"
              {...(runOneTime === 0 && { layout: true })}
              initial={{ translateX: runOneTime === 0 ? 200 : 0 }}
              animate={{ translateX: 0 }}
              transition={{ type: "spring", damping: 20, stiffness: 100 }}
            />
          )}
          <motion.img
            {...(runOneTime === 0 && { layout: true })}
            src={mainLogoX}
            alt="X"
            initial={{
              opacity: runOneTime === 0 ? 1 : 0,
              scale: runOneTime === 0 ? 0.5 : 1,
            }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              type: "spring",
              damping: 10,
              stiffness: 100,
              duration: 0.5,
            }}
            className={logoClass}
          />
        </motion.div>
      )}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 3.4, duration: 0.5 } }}
        className={`flex w-full justify-center ${
          selectedIllustration === null ? "gap-2" : ""
        }`}
        onClick={() =>
          selectedIllustration !== null && setSelectedIllustration(null)
        }
      >
        {illustrations.map((illustration, index) => (
          <div key={index} className="relative">
            <AnimatePresence>
              {index === 0 && selectedIllustration !== 0 && showClickBtn && (
                <div className="absolute h-fit w-fit  text-sm top-0  mt-[-3.5em] ml-[-0.5em] rounded-lg text-black">
                  <motion.div
                    initial={{ y: 10, opacity: 0 }}
                    animate={{
                      y: [0, -10, 0],
                      opacity: 1,
                    }}
                    exit={{ opacity: 0 }}
                    transition={{
                      y: {
                        repeat: 2,
                        repeatType: "reverse",
                        duration: 1,
                        ease: "easeInOut",
                      },
                      opacity: {
                        duration: 1, // Adjust opacity animation duration as needed
                      },
                    }}
                    className="relative click-me-btn border-2 border-yellow-500 rounded-lg bg-white"
                  >
                    <p className="whitespace-nowrap  p-[2px] px-1">click me</p>
                    <div className="border-b-2 border-r-2 border-yellow-500 rounded-br-[1px] bg-white absolute  bottom-[-1px] left-1/2 -translate-x-1/2 translate-y-1/2 h-3 w-3 rotate-45"></div>
                  </motion.div>
                </div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {selectedIllustration !== null && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="top-5 left-0 w-full fixed text-center font-semibold"
                >
                  {illustrations[Number(selectedIllustration)].title}
                </motion.div>
              )}
            </AnimatePresence>

            {(selectedIllustration === null ||
              selectedIllustration === index) && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={getIllustrationClass(index)}
              >
                <motion.img
                  src={illustration.src}
                  alt={illustration.alt}
                  layout
                  transition={{ duration: 0.3 }}
                  onClick={() => handleIllustrationClick(index)}
                  className={`w-fit cursor-pointer ${
                    selectedIllustration === index
                      ? "h-[10rem] pb-0 p-5 mt-16"
                      : "h-[3em]"
                  }`}
                  key={index}
                />
              </motion.div>
            )}
          </div>
        ))}
      </motion.div>

      {selectedIllustration !== null && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          className="px-8 text-center"
        >
          {illustrations[selectedIllustration].description}
        </motion.div>
      )}

      <div>
        {selectedIllustration === null && showText && (
          <motion.div
            key={featureIndex}
            initial={{ opacity: 0, translateY: 20 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 1 }}
            className="mb-6"
          >
            <p className="text-center text-lg font-semibold">
              {features[featureIndex].point1}
            </p>
            {features[featureIndex].point2 && (
              <p className="text-center text-lg font-semibold">
                {features[featureIndex].point2}
              </p>
            )}
          </motion.div>
        )}

        <div className="flex flex-col gap-4 w-full text-md font-semibold p-6 pt-0">
          <button
            onClick={() => createNewWallet()}
            className="p-4 w-full duration-200 rounded-[2rem] bg-white text-black hover:bg-opacity-80"
          >
            {buttonTitle}
          </button>
          <button
            onClick={() => navigate("/use-existing-wallet")}
            className="p-4 rounded-[2rem] duration-200 w-full hover:bg-zinc-800 whitespace-nowrap"
          >
            I already have a Wallet
          </button>
        </div>
      </div>
    </div>
  );
};

export default Registration;
