import { X } from "react-feather";
import CustomToken from "../DashboardComponents/CustomToken";

type ImportTokenModalParams = {
  isOpen: boolean;
  onClose: () => void;
};

// TODO update the state in the TokenList if a new token is added.
const ImportToken = ({ isOpen, onClose }: ImportTokenModalParams) => {
  const isModalOpen = isOpen;

  return (
    <>
      {isModalOpen && (
        <div className="absolute top-0 left-0 right-0 w-full h-full bg-primary-bg bg-opacity-80 z-50 flex justify-center items-center">
          <div className="flex flex-col gap-3 p-4 w-[90%] max-w-md justify-center items-center rounded-xl shadow-lg bg-secondary-bg">
            <div className="relative w-full text-center m-auto">
              <span className="text-md font-bold text-neutral-200">
                Import tokens
              </span>
              <button
                className="absolute right-0 hover:opacity-70"
                onClick={onClose}
              >
                <X />
              </button>
            </div>

            <CustomToken onClose={onClose} />
          </div>
        </div>
      )}
    </>
  );
};

export default ImportToken;
