import { useState } from "react";
import { Link } from "react-router-dom";
import close from "../../assets/NftDiscoveryPage/close.svg";
import arrow from "../../assets/arrow-right-white.svg";
import { getShortDisplayString, copyToClipboard } from "../../utils/helper";
import { TransactionData } from "../../constants/Types";

type TransactionHistoryModalParams = {
  txnDetails: TransactionData;
  onClose: () => void;
};

const TransactionHistoryModal = ({
  txnDetails,
  onClose,
}: TransactionHistoryModalParams) => {
  const [addABlockExplorer, setAddABlockExplorer] = useState(false);

  const correctAmount = (amount: string) => {
    if (amount.includes(".")) {
      return amount;
    }

    return `${Number(txnDetails.amount) / 10 ** 18}`;
  };

  const handleChildClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className="absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%]  w-4/5 cursor-default ">
      <div
        className="text-sm p-4  bg-card-bg z-10 rounded-xl"
        onClick={handleChildClick}
      >
        <img
          src={close}
          onClick={onClose}
          className="h-6 w-6 float-right cursor-pointer"
          alt="close"
        />
        <p className="mb-6 font-semibold text-center capitalize text-md">
          {txnDetails.label}
        </p>

        <div className="flex justify-between mb-2">
          <p className="font-semibold">Status</p>
          {txnDetails.explorer ? (
            <p
              className="text-blue-500 cursor-pointer"
              onClick={() =>
                window.open(
                  `${txnDetails.explorer + txnDetails.transaction_id}`,
                  "_blank",
                )
              }
            >
              View on block explorer
            </p>
          ) : (
            <p
              className="text-blue-500"
              onClick={() => setAddABlockExplorer(true)}
            >
              Add on block explorer
            </p>
          )}
        </div>
        <div className="flex justify-between gap-2 mb-4">
          {txnDetails.status === "1" ? (
            <p className="text-green-500 text-sm">Confirmed</p>
          ) : (
            <p className="text-red-500 text-sm">Rejected</p>
          )}
          <p
            className="text-blue-500 cursor-pointer"
            onClick={() => copyToClipboard(txnDetails.transaction_id || "")}
          >
            Copy transaction ID
          </p>
        </div>

        <div className="bg-card-bg2 p-4 rounded-xl mb-4">
          <div className="flex justify-between mb-2">
            <p>From</p>
            <p>To</p>
          </div>
          <div className="flex justify-between items-center">
            <p>{getShortDisplayString(txnDetails.from_address)}</p>
            <img
              src={arrow}
              className="h-7 w-7 p-1 border rounded-full"
              alt="arrow"
            />
            <p>{getShortDisplayString(txnDetails.to_address)}</p>
          </div>
        </div>

        <div>
          <p className="font-semibold mb-2">Transaction</p>
          <div className="flex justify-between mb-1 text-gray-500">
            <p>Nonce</p>
            <p>{txnDetails.nonce}</p>
          </div>
          <div className="flex justify-between mb-1 text-gray-500">
            <p>Amount</p>
            <p className="text-gray-500">
              <p>
                {txnDetails.label.includes("Sent")
                  ? `- ${correctAmount(txnDetails.amount)}`
                  : `${correctAmount(txnDetails.amount)}`}
              </p>
            </p>
          </div>
          {txnDetails.gas_limit && (
            <div className="flex justify-between mb-1 text-gray-500">
              <p>Gas Limit</p>
              <p>{txnDetails.gas_limit}</p>
            </div>
          )}
          {txnDetails.gas_price && (
            <div className="flex justify-between mb-1 text-gray-500">
              <p>Gas Price</p>
              <p>{txnDetails.gas_price}</p>
            </div>
          )}
          {txnDetails.gas_used && (
            <div className="flex justify-between mb-1 text-gray-500">
              <p>Gas Used</p>
              <p>{txnDetails.gas_used}</p>
            </div>
          )}
        </div>
        {addABlockExplorer && (
          <div className="absolute rounded-lg top-0 left-0 h-full w-full bg-primary-bg p-2 overflow-y-scroll">
            <div className="mb-2">
              <p className="semibold">Network name</p>
              <input type="text" className="p-1 rounded w-full" />
            </div>
            <div className="mb-2">
              <p className="semibold">New RPC URL</p>
              <input type="text" className="p-1 rounded w-full" />
            </div>
            <div className="mb-2">
              <p className="semibold">Chain ID</p>
              <input type="text" className="p-1 rounded w-full" />
            </div>
            <div className="mb-2">
              <p className="semibold">Currency Symbol</p>
              <input type="text" className="p-1 rounded w-full" />
            </div>
            <div className="mb-4">
              <p className="semibold">Block explorer URL (Optional)</p>
              <input type="text" className="p-1 rounded w-full" />
            </div>
            <div className="flex justify-around gap-2">
              <button
                className="w-[48%] bg-blue-500 p-2 rounded"
                onClick={() => setAddABlockExplorer(false)}
              >
                Add
              </button>
              <button
                className="w-[48%] bg-blue-500 p-2 rounded"
                onClick={() => setAddABlockExplorer(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>

      {txnDetails.isGasless && (
        <div>
          <Link
            to="/mineboard"
            className="!w-full block text-sm text-green-800 bg-green-200 border-green-800 rounded-md p-2 mt-2 text-center"
          >
            Please visit the Mineboard to claim your rewards.
          </Link>
        </div>
      )}
    </div>
  );
};

export default TransactionHistoryModal;
