import { useEffect, useRef, useState } from "react";
import { Search } from "react-feather";
import SelectAsset from "./AssetSelectionComponents/Tabs/Tabs";
import { AssetSelectionDrawerType } from "../constants/Types";

const AssetSelectionDrawer = ({
  isOpen,
  onClose,
  transactionUID,
  assetUID,
  drawerHeading,
  assetSelectionTask,
}: AssetSelectionDrawerType) => {
  const drawer = useRef(null);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    const closeDrawerOnOutsideClick = (e: any) => {
      if (!(drawer.current as any).contains(e.target)) {
        onClose(transactionUID);
      }
    };

    document.addEventListener("mousedown", closeDrawerOnOutsideClick);

    return () => {
      document.removeEventListener("mousedown", closeDrawerOnOutsideClick);
    };
  });

  return (
    <div
      ref={drawer}
      className={`max-w-[584px] ${
        isOpen ? "bottom-0" : " translate-y-full"
      } ${transactionUID} flex flex-col flex-grow overflow-y-auto justify-start fixed bottom-0 left-1/2 translate-x-[-50%] w-[95%] h-[60%] bg-secondary-bg  text-white  rounded-t-3xl  transition duration-500  transform z-50 `}
    >
      <div className="sticky top-0 bg-secondary-bg pt-5">
        <h1 className="text-center font-semibold text-md">{drawerHeading}</h1>

        {/* Search Input Box  */}
        <div className="px-4">
          <div className="flex items-center max-w-[95%] mx-auto border border-gray-300 rounded-lg my-4 p-2 text-sm">
            <button className="min-w-fit  pr-1 opacity-60">
              <Search className="h-5 mx-auto my-auto" />
            </button>
            <input
              type="text"
              placeholder="Search..."
              className="w-full focus:outline-none pl-1 bg-transparent"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      {/* Tabs for token and nft selection  */}
      <SelectAsset
        onClose={() => onClose(transactionUID)}
        transactionUID={transactionUID}
        assetUID={assetUID}
        searchValue={searchValue}
        assetSelectionTask={assetSelectionTask}
      />
    </div>
  );
};

export default AssetSelectionDrawer;
