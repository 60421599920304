import { X } from "react-feather";
import { useState } from "react";
import localforage from "localforage";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { Button } from "../ui/button";
import {
  getShortDisplayString,
  getUserSettingsData,
  log,
} from "../../utils/helper";
import {
  AddressInAddressBookType,
  RenameAddressModalType,
  UserSettingsType,
} from "../../constants/Types";
import { STORAGE_KEYS } from "../../constants/Enums";
import Web3AvatarComponent from "../Web3AvatarComponent";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

const RenameAddressModal = ({
  isOpen,
  onClose,
  address,
  name,
}: RenameAddressModalType) => {
  const [enteredName, setEnteredName] = useState<string>("");
  const location = useLocation();
  const currentRoute = location.pathname;
  const { eoaAddress } = useWalletConfig();

  const updateNameInAddressBook = async (_name: string, _address: string) => {
    try {
      const currentAddressBook: UserSettingsType[] =
        await getUserSettingsData();

      const userIndex = currentAddressBook.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      const addressBookOfEOA =
        currentAddressBook[userIndex].settings.addressBook;

      // Check if the name is already associated with a different address
      const existingNameEntry = addressBookOfEOA.find(
        (entry: AddressInAddressBookType) =>
          entry.name === _name && entry.address !== _address,
      );

      if (existingNameEntry) {
        toast.error(
          `Name "${_name}" is already associated with a different address.`,
        );
        return;
      }

      const existingAddressIndex = addressBookOfEOA.findIndex(
        (entry: AddressInAddressBookType) => entry.address === _address,
      );

      if (existingAddressIndex !== -1) {
        // Address already exists, update the name
        const updatedAddressBookOfEOA = [...addressBookOfEOA];

        updatedAddressBookOfEOA[existingAddressIndex].name = _name;

        currentAddressBook[userIndex].settings.addressBook =
          updatedAddressBookOfEOA;
        await localforage.setItem(
          STORAGE_KEYS.USER_SETTINGS,
          currentAddressBook,
        );

        toast.success("Name is updated in the address book");
      } else if (currentRoute === "/dashboard/transaction/add-address") {
        const newAddressEntry: AddressInAddressBookType = {
          name: _name,
          address: _address,
        };

        currentAddressBook[userIndex].settings.addressBook = [
          ...addressBookOfEOA,
          newAddressEntry,
        ];
        await localforage.setItem(
          STORAGE_KEYS.USER_SETTINGS,
          currentAddressBook,
        );

        toast.success("New address added to the address book");
      } else {
        toast.error("Address not found in the address book.");
      }
    } catch (error) {
      log("Error updating Name in AddressBook:", error, "error");
    }
  };

  const handleSaveBtn = (_name: string, _address: string) => {
    if (_name === "") {
      toast.error("Please enter a valid Name");
    } else {
      updateNameInAddressBook(_name, _address);
      onClose();
    }
  };

  const handleInputChange = (e: any) => {
    const _enteredName = e.target.value;

    setEnteredName(_enteredName);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-1/2 -translate-x-1/2 w-full max-w-[600px] h-full  bg-primary-bg bg-opacity-50 z-50 flex justify-center items-center">
          <div className=" flex flex-col gap-3 p-4 w-[85%]  justify-center items-center  rounded-xl shadow-lg bg-secondary-bg ">
            <div className="relative w-full text-center m-auto">
              <span className=" text-md font-bold text-neutral-200 ">
                Add Address
              </span>
              <button
                className="absolute right-0 hover:opacity-70 "
                onClick={() => {
                  onClose();
                }}
              >
                <X style={{ color: "#FFFFFF", fill: "#FFFFFF" }} />
              </button>
            </div>

            <div className="flex flex-col gap-2 justify-center items-center">
              <Web3AvatarComponent address={address} className=" w-20 h-20" />

              <label className="w-fit flex  flex-col">
                <input
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  className="w-fit rounded-md text-md text-center  px-2  pt-2  focus:outline-none bg-transparent text-neutral-300"
                  type="text"
                  placeholder={name === "" ? "Enter a name" : name}
                  maxLength={15}
                />
              </label>

              <p className="w-full text-neutral-500 font-semibold text-center text-sm">
                {getShortDisplayString(address)}
              </p>
            </div>

            <Button
              onClick={() => {
                handleSaveBtn(enteredName, address);
              }}
              disabled={enteredName === ""}
              className={`group w-full transition-transform bg-card-bg2  hover:bg-card-bg2 `}
            >
              {currentRoute === "/dashboard/transaction/add-address" ? (
                <span className="group-hover:scale-105">Save</span>
              ) : (
                <span className="group-hover:scale-105">Update</span>
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default RenameAddressModal;
