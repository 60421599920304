import { Scanner } from "@yudiel/react-qr-scanner";

// Styles
import "./index.css";

import { log } from "../../utils/helper";

const QrReader = ({
  onScanSuccessful,
}: {
  onScanSuccessful: (e: any) => any;
}) => {
  // Success
  const onScanSuccess = async (result: any) => {
    const wcUrl = result?.[0].rawValue;

    onScanSuccessful({
      preventDefault: () => {
        log("Prevent default from QR scanner");
      },

      target: { uri: { value: wcUrl } },
    });
  };

  // Fail
  const onScanFail = (err: string | Error) => {
    log("Error in scan", err, "error");
  };

  return (
    <div className="qr-reader">
      <Scanner
        onScan={(result) => onScanSuccess(result)}
        onError={(error: any) => onScanFail(error)}
      />
    </div>
  );
};

export default QrReader;
