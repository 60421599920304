import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { X } from "lucide-react";

import * as Config from "../../config/env";
import {
  getChainDetails,
  getItemFromStorage,
  getExtensionURL,
} from "../../utils/helper";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";
import { STORAGE_KEYS } from "../../constants/Enums";
import GradientBg from "../../components/GradientBg";

const BuySell = () => {
  const [url, setUrl] = useState<string>("");
  const location = useLocation();
  const navigateTo = useNavigate();
  const { chainID } = useWalletConfig();

  useEffect(() => {
    const currentChainDetails = getChainDetails(chainID);
    const isOnlyEOA = currentChainDetails?.isOnlyEoa || false;

    const address = getItemFromStorage(
      isOnlyEOA ? STORAGE_KEYS.SIGNER : STORAGE_KEYS.SMART_ACCOUNT,
    );

    setUrl(
      `${Config.ONRAMP_URL}/${location.pathname.slice(1)}/?appId=${
        Config.ONRAMP_APP_ID
      }&walletAddress=${address}`,
    );
  }, []);

  const handleClose = () => {
    navigateTo(getExtensionURL());
  };

  return (
    <>
      <GradientBg />
      <div className="relative w-full h-full p-7 ">
        <div className="absolute top-0 right-0 z-10 p-1 bg-transparent">
          <button
            onClick={handleClose}
            className="p-2 hover:bg-gray-100 hover:text-black text-white transition-colors rounded-full bg-transparent"
            aria-label="Close"
          >
            <X size={20} className="bg-transparent" />
          </button>
        </div>
        <iframe
          title="Buy/Sell"
          className="w-full h-full border-none rounded-2xl z-50"
          src={url}
        />
      </div>
    </>
  );
};

export default BuySell;
