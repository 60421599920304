import { useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getItemFromStorage, getChainDetails } from "../../utils/helper";
import PageTitle from "../../components/PageTitle";
import { PAGE_TITLE_BUTTON_TYPE, STORAGE_KEYS } from "../../constants/Enums";
import PageTransitionWrapper from "../../components/PageTransitionWrapper";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";
import SwapAndBridge from "../../components/SwapAndBridge";
import { swapBridgeState } from "../../state/SwapBridgeState";
import useWallet from "../../lib/store/hooks/useWallet";
import { SwapBridgeStateType } from "../../constants/Types";

type SwapBridgeType = {
  isBridge: boolean;
};

const SwapBridge = ({ isBridge }: SwapBridgeType) => {
  const { smartAccountAddress, eoaAddress, userSettings } = useWallet();
  const setSwapBridgeData = useSetRecoilState(swapBridgeState);
  const [, setChainLogo] = useState("");
  const { chainID } = useWalletConfig();

  const location = useLocation();
  const backLink = location.state ? location.state.from : "/dashboard";

  useEffect(() => {
    const currentChainDetails = getChainDetails(chainID);

    if (currentChainDetails) {
      setChainLogo(currentChainDetails.chainUri);

      const isOnlyEOA = currentChainDetails.isOnlyEoa || false;
      const address = getItemFromStorage(
        isOnlyEOA ? STORAGE_KEYS.SIGNER : STORAGE_KEYS.SMART_ACCOUNT,
      );

      const data: SwapBridgeStateType = {
        uid: crypto.randomUUID(),
        name: "",
        usersDomainName: "",
        address: userSettings?.isEoaSelected
          ? eoaAddress || ""
          : address || smartAccountAddress || "",
        fromAsset: null,
        toAsset: null,
        fromChain: currentChainDetails,
        toChain: currentChainDetails,
      };

      setSwapBridgeData([data]);
    }
  }, [chainID]);

  return (
    <PageTransitionWrapper>
      <div className="w-full mx-auto overflow-hidden no-scrollbar bg-primary-bg h-full text-white select-none">
        <PageTitle
          title={`${isBridge ? "Bridge" : "Swap"}`}
          leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
          rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
          leftDestinationRoute={backLink}
        />

        <div className="flex flex-col gap-4 justify-center items-center mt-6 "></div>

        <SwapAndBridge isBridge={isBridge} />
      </div>
    </PageTransitionWrapper>
  );
};

export default SwapBridge;
