import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { AnimatePresence } from "framer-motion";
import { supportedChainsState } from "../../state/SupportedChainsState";
import { getItemFromStorage } from "../../utils/helper";
import { STORAGE_KEYS } from "../../constants/Enums";
import ChainSelection from "../Modals/ChainSelection";
import worldIcon from "../../assets/world.svg";

const SwitchNetwork = ({ marqueeVisible }: { marqueeVisible: boolean }) => {
  const [Chains] = useRecoilState(supportedChainsState);
  const [isChainSelectionModalOpen, setIsChainSelectionModalOpen] =
    useState(false);
  const [currentChainLogo, setCurrentChainLogo] = useState("");
  const [currentCoinName, setCurrentCoinName] = useState("");
  const [currentChainName, setCurrentChainName] = useState("");

  const storageChainId = getItemFromStorage(STORAGE_KEYS.NETWORK);

  const getCurrentChainName = () => {
    const currentChain = Chains.find(
      (chain) => chain.chainId === storageChainId,
    );

    setCurrentChainName(currentChain?.name || "");
  };

  useEffect(() => {
    getCurrentChainName();
  }, [storageChainId]);

  useEffect(() => {
    if (storageChainId) {
      const currentChain = Chains.find((ch) => ch.chainId === storageChainId);

      setCurrentChainLogo(currentChain?.chainUri ?? "");
      setCurrentCoinName(currentChain?.nativeAsset ?? "");
    } else {
      setCurrentChainLogo(Chains[0]?.chainUri);
      setCurrentCoinName(Chains[0]?.nativeAsset);
    }
  }, [storageChainId]);

  const openChainSelectionModal = () => {
    setIsChainSelectionModalOpen(true);
  };

  return (
    <>
      <div
        className={`group w-fit absolute left-2 top-3 flex align-center justify-center bg-primary-bg rounded-full p-2 hover:bg-card-bg2 duration-200 cursor-pointer z-20`}
        onClick={openChainSelectionModal}
      >
        <img
          className="h-5 rounded-full"
          src={currentChainLogo || worldIcon}
          alt={`${currentCoinName} logo`}
        />
        <span className="flex flex-col justify-center items-center absolute -bottom-12 left-0 scale-0 rounded bg-primary-bg shadow-xl p-1 px-2 text-sm text-white group-hover:scale-100 whitespace-nowrap">
          {currentChainName}
          <span className="text-xs">(click to change)</span>
        </span>
      </div>
      <AnimatePresence>
        {isChainSelectionModalOpen && (
          <ChainSelection
            onClose={() => setIsChainSelectionModalOpen(false)}
            marqueeVisible={marqueeVisible}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default SwitchNetwork;
