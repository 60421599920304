import { useEffect, useState } from "react";
import { fetchAssets } from "../slices/assetsSlice";
import { getItemFromStorage } from "../../../utils/helper";
import { STORAGE_KEYS } from "../../../constants/Enums";
import useWallet from "../../../lib/store/hooks/useWallet";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

const useAssets = () => {
  const dispatch = useAppDispatch();
  const [assetsTimerStart, setAssetsTimerStart] = useState(false);
  const { tokens, loading, error } = useAppSelector((state) => state.assets);
  const { smartAccountAddress, getProvider, userSettings, eoaAddress } =
    useWallet();

  useEffect(() => {
    const interval = setInterval(() => {
      setAssetsTimerStart(false);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchAssetsData = async () => {
      const chain = getItemFromStorage(STORAGE_KEYS.NETWORK);
      const chainId = chain?.toString();
      const provider = getProvider();
      const smartAccountAddressInStorage = getItemFromStorage(
        STORAGE_KEYS.SMART_ACCOUNT,
      );
      const signerAccountAddressInStorage = getItemFromStorage(
        STORAGE_KEYS.SIGNER,
      );
      const userAddress = userSettings?.isEoaSelected
        ? userSettings.address || eoaAddress || signerAccountAddressInStorage
        : smartAccountAddressInStorage || smartAccountAddress;

      if (chainId && provider && userAddress) {
        if (!assetsTimerStart) {
          dispatch(fetchAssets({ chainId, userAddress, provider }));
          setAssetsTimerStart(true);
        }
      }
    };

    fetchAssetsData();
  }, [
    dispatch,
    smartAccountAddress,
    getProvider,
    eoaAddress,
    userSettings?.isEoaSelected,
    tokens,
    tokens.length,
    tokens[0]?.name,
    loading,
    error,
  ]);

  return { tokens, loading, error };
};

export default useAssets;
