import { ASSET_TYPE } from "../../constants/Enums";
import { AssetCardType } from "../../constants/Types";
import NFTAssetCard from "./NFTAssetCard";
import TokenAssetCard from "./TokenAssetCard";

const AssetCard = ({
  assetCardSelectionTask,
  asset,
  transactionUID,
  openAssetSelectionDrawer,
  openRemoveAssetModal,
  isProceedEnable,
  isReview,
}: AssetCardType) => {
  if (asset.assetType === ASSET_TYPE.TOKEN && asset.tokenDetails) {
    return (
      <TokenAssetCard
        assetCardSelectionTask={assetCardSelectionTask}
        token={asset.tokenDetails}
        transactionUID={transactionUID}
        assetUID={asset.assetUID}
        openAssetSelectionDrawer={() => {
          if (openAssetSelectionDrawer) {
            openAssetSelectionDrawer();
          }
        }}
        openRemoveAssetModal={() => {
          if (openRemoveAssetModal) {
            openRemoveAssetModal();
          }
        }}
        isProceedEnable={isProceedEnable}
        isReview={isReview}
      />
    );
  }

  if (asset.assetType === ASSET_TYPE.NFT && asset.nftDetails) {
    return (
      <NFTAssetCard
        assetCardSelectionTask={assetCardSelectionTask}
        nft={asset.nftDetails}
        transactionUID={transactionUID}
        assetUID={asset.assetUID}
        openAssetSelectionDrawer={() => {
          if (openAssetSelectionDrawer) {
            openAssetSelectionDrawer();
          }
        }}
        openRemoveAssetModal={() => {
          if (openRemoveAssetModal) {
            openRemoveAssetModal();
          }
        }}
      />
    );
  }

  return <></>;
};

export default AssetCard;
