import { WalletKit } from "@reown/walletkit";
import { Core } from "@walletconnect/core";

import Manifest from "../../manifest/manifest.json";
import ConnectionData from "../../manifest/connection/connection.json";
import ManifestData from "../../manifest.json";

import { WALLETCONNECT_PROJECT_ID } from "../../config/env";

export default function initiateWC(setWcInstance: any) {
  return async () => {
    const core = new Core({ projectId: WALLETCONNECT_PROJECT_ID });

    const wcInstance = await WalletKit.init({
      core,
      metadata: {
        name: ManifestData.action.default_title,
        description: Manifest.description,
        url: ConnectionData.connection.rdns,
        icons: [ManifestData.action.default_icon],
      },
    });

    setWcInstance(wcInstance);
  };
}
