import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { createSmartAccountClient } from "@biconomy/account";
import {
  setItemInStorage,
  getItemFromStorage,
  getCoinBalance,
  getChainDetails,
  setChains,
  generateEoaWithCredentialId,
  removeItemFromStorage,
  getUserSettingsData,
  log,
  setUserSettingsData,
  generateEoaWithMnemonic,
  generateMnemonicAndEoa,
  errorHandler,
} from "../../../utils/helper";
import {
  STORAGE_KEYS,
  DEFAULT_CHAIN_ID,
  ERROR_EVENTS,
} from "../../../constants/Enums";
import { SupportedChainType, UserSettingsType } from "../../../constants/Types";
import { createNewSmartAccount } from "../../../utils/api";

interface AuthState {
  isLoggedIn: boolean;
}

interface WalletState {
  chainData: SupportedChainType | null;
  eoaAddress: string | null;
  eoaBalance: string | null;
  eoaPrivateKey: string | null;
  smartAccountAddress: string | null;
  smartAccountBalance: string | null;
  isConnected: boolean;
  isInitialized: boolean;
  userSettings: UserSettingsType | null;
  provider: ethers.providers.JsonRpcProvider | null;
  auth: AuthState;
  activeSmartAccountIndex: number;
}

const initialState: WalletState = {
  chainData: null,
  eoaAddress: null,
  eoaBalance: null,
  eoaPrivateKey: null,
  smartAccountAddress: null,
  smartAccountBalance: null,
  isConnected: false,
  isInitialized: false,
  userSettings: null,
  provider: null,
  activeSmartAccountIndex: 0,
  auth: {
    isLoggedIn: !!getItemFromStorage(STORAGE_KEYS.IS_LOGGED_IN),
  },
};

const getRpcProvider = (
  provider: ethers.providers.JsonRpcProvider | null,
  rpc: string | undefined,
) => {
  if (provider && provider?.connection?.url === rpc) {
    return provider;
  }

  return new ethers.providers.JsonRpcProvider(rpc);
};

export const initializeWallet = createAsyncThunk(
  "wallet/initialize",
  async ({
    chainId,
    walletName,
  }: {
    chainId?: number;
    walletName?: string;
  }) => {
    await setChains([]);
    const chainData = getChainDetails(chainId || DEFAULT_CHAIN_ID); // can have chain id from the storage

    let activeSmartAccountIndex = 0;
    let userSettings: UserSettingsType | null = null;

    if (walletName) {
      const existingUserSettings = await getUserSettingsData();

      userSettings =
        existingUserSettings.find(
          (setting) => setting.walletName === walletName,
        ) ?? null;

      if (userSettings) {
        const { accounts } = userSettings;

        activeSmartAccountIndex = accounts.findIndex(
          (account) =>
            account.address === userSettings?.activeSmartAccountAddress,
        );
      }
    }

    return { chainData, userSettings, activeSmartAccountIndex };
  },
);

// credId is necessary when registering a new user
// take the mnemonic phrase from the userSettings object and generate the EOA
export const initializeEOA = createAsyncThunk(
  "wallet/initializeEOA",
  async (
    {
      credentialId,
      seedPhrase,
    }: { credentialId?: string; seedPhrase?: string },
    { getState },
  ) => {
    const state = getState() as { wallet: WalletState };
    const { chainData, userSettings, provider: stateProvider } = state.wallet;
    let EOA = null;

    if (seedPhrase !== undefined) {
      EOA = generateEoaWithMnemonic(seedPhrase ?? userSettings?.mnemonic ?? "");
    } else if (userSettings && userSettings?.mnemonic !== null) {
      const { eoa } = generateMnemonicAndEoa(userSettings);

      EOA = eoa;
    } else if (credentialId || userSettings?.id !== "") {
      const credID = credentialId || userSettings?.id || "";
      const { eoa } = generateEoaWithCredentialId(credID);

      EOA = eoa;
    }

    const provider = getRpcProvider(stateProvider, chainData?.rpc);

    const balance = await getCoinBalance(EOA.address, provider);

    setItemInStorage(STORAGE_KEYS.SIGNER, EOA.address);
    setItemInStorage(STORAGE_KEYS.NETWORK, chainData?.chainId);

    return {
      eoaAddress: EOA.address,
      eoaPrivateKey: EOA.privateKey,
      balance,
      provider,
    };
  },
);

export const initializeSmartWallet = createAsyncThunk(
  "wallet/initializeSmartWallet",
  async (
    { smartAccountIndex }: { smartAccountIndex?: number },
    { getState, dispatch },
  ) => {
    const state = getState() as { wallet: WalletState };
    const {
      smartAccountAddress: smartAccAddress,
      smartAccountBalance: smartAccBalance,
      chainData,
      eoaPrivateKey,
      activeSmartAccountIndex,
      userSettings,
      provider: stateProvider,
    } = state.wallet;

    const provider = getRpcProvider(stateProvider, chainData?.rpc);

    if (!eoaPrivateKey || !provider) {
      log("Signer or provider not initialized");
      return null;
    }

    try {
      if (chainData?.isOnlyEoa) {
        if (userSettings) {
          const allUserSettings = await getUserSettingsData();
          const currentUserSettingsIndex = allUserSettings.findIndex(
            (settings) => settings.walletName === userSettings.walletName,
          );

          if (currentUserSettingsIndex !== -1) {
            const updatedUserSettings = {
              ...userSettings,
              isEoaSelected: true,
            };

            const updatedAllUserSettings = [
              ...allUserSettings.slice(0, currentUserSettingsIndex),
              updatedUserSettings,
              ...allUserSettings.slice(currentUserSettingsIndex + 1),
            ];

            await setUserSettingsData(updatedAllUserSettings);

            // eslint-disable-next-line no-use-before-define
            dispatch(setUserSettings(updatedUserSettings));
          }
        }

        return {
          smartAccountAddress: smartAccAddress,
          smartAccountBalance: smartAccBalance,
        };
      }

      const signer = new ethers.Wallet(eoaPrivateKey, provider);

      const biconomySmartAccountConfig: any = {
        signer,
        chainId: chainData?.chainId,
        rpcUrl: chainData?.rpc,
        bundlerUrl: chainData?.bundlerUrl,
        biconomyPaymasterApiKey: chainData?.paymasterApiKey,
      };

      if (smartAccountIndex !== undefined) {
        biconomySmartAccountConfig.index = smartAccountIndex;
      } else {
        biconomySmartAccountConfig.index = activeSmartAccountIndex;
      }

      const smartAccount = await createSmartAccountClient(
        biconomySmartAccountConfig,
      );
      const smartAccountAddress = await smartAccount.getAccountAddress();
      const smartAccountBalance = await getCoinBalance(
        smartAccountAddress,
        provider,
      );

      setItemInStorage(STORAGE_KEYS.SMART_ACCOUNT, smartAccountAddress);

      if (userSettings) {
        const allUserSettings = await getUserSettingsData();
        const currentUserSettingsIndex = allUserSettings.findIndex(
          (settings) => settings.walletName === userSettings.walletName,
        );

        if (currentUserSettingsIndex !== -1) {
          const updatedUserSettings = {
            ...userSettings,
            activeSmartAccountAddress: smartAccountAddress,
          };

          const updatedAllUserSettings = [
            ...allUserSettings.slice(0, currentUserSettingsIndex),
            updatedUserSettings,
            ...allUserSettings.slice(currentUserSettingsIndex + 1),
          ];

          await setUserSettingsData(updatedAllUserSettings);

          // eslint-disable-next-line no-use-before-define
          dispatch(setUserSettings(updatedUserSettings));
        }
      }

      return { smartAccountAddress, smartAccountBalance };
    } catch (error) {
      log("Error initializing smart wallet:", error);
      errorHandler(error, ERROR_EVENTS.SMART_WALLET_ERROR);
      return null;
    }
  },
);

export const addNewSmartAccount = createAsyncThunk(
  "wallet/addNewSmartAccount",
  async (_, { getState, dispatch }) => {
    const state = getState() as { wallet: WalletState };
    const {
      chainData,
      eoaPrivateKey,
      userSettings,
      provider: stateProvider,
      eoaAddress,
    } = state.wallet;

    if (!chainData || !eoaPrivateKey || !userSettings) {
      throw new Error("Required wallet data is missing");
    }

    const signer = new ethers.Wallet(
      eoaPrivateKey,
      getRpcProvider(stateProvider, chainData.rpc),
    );

    const newSmartAccountIndex = userSettings.accounts.length;

    const biconomySmartAccountConfig = {
      signer,
      chainId: chainData.chainId,
      rpcUrl: chainData.rpc,
      bundlerUrl: chainData.bundlerUrl,
      biconomyPaymasterApiKey: chainData.paymasterApiKey,
      index: newSmartAccountIndex,
    };

    const smartAccount = await createSmartAccountClient(
      biconomySmartAccountConfig,
    );
    const smartAccountAddress = await smartAccount.getAccountAddress();

    const newAccount = {
      address: smartAccountAddress,
      name: `Account ${newSmartAccountIndex + 1}`,
      position: newSmartAccountIndex + 1,
    };

    try {
      await createNewSmartAccount({
        eoaAddress: eoaAddress || "",
        smartAccountAddress: smartAccountAddress && smartAccountAddress,
      });
    } catch (error) {
      log("Error creating user data:", error);
    }

    const allUserSettings = await getUserSettingsData();

    const currentUserSettingsIndex = allUserSettings.findIndex(
      (settings) => settings.walletName === userSettings.walletName,
    );

    if (currentUserSettingsIndex === -1) {
      throw new Error("Current user settings not found");
    }

    const updatedUserSettings: UserSettingsType = {
      ...userSettings,
      accounts: [...userSettings.accounts, newAccount],
    };

    const updatedAllUserSettings = [
      ...allUserSettings.slice(0, currentUserSettingsIndex),
      updatedUserSettings,
      ...allUserSettings.slice(currentUserSettingsIndex + 1),
    ];

    // Save all user settings back to storage
    await setUserSettingsData(updatedAllUserSettings);
    setItemInStorage(STORAGE_KEYS.NETWORK, chainData.chainId);

    // eslint-disable-next-line no-use-before-define
    dispatch(setUserSettings(updatedUserSettings));

    return { smartAccountAddress, newAccountIndex: newSmartAccountIndex };
  },
);

export const login = createAsyncThunk(
  "wallet/login",
  async (_, { dispatch }) => {
    setItemInStorage(STORAGE_KEYS.IS_LOGGED_IN, true);
    // eslint-disable-next-line no-use-before-define
    dispatch(setIsLoggedIn(true));
  },
);

export const logout = createAsyncThunk(
  "wallet/logout",
  async (_, { dispatch }) => {
    await removeItemFromStorage(STORAGE_KEYS.SMART_ACCOUNT);
    await removeItemFromStorage(STORAGE_KEYS.SIGNER);
    await removeItemFromStorage(STORAGE_KEYS.IS_LOGGED_IN);
    // eslint-disable-next-line no-use-before-define
    dispatch(setIsLoggedIn(false));
  },
);

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setIsConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setUserSettings: (state, action: PayloadAction<UserSettingsType>) => {
      state.userSettings = action.payload;
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.auth.isLoggedIn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeWallet.fulfilled, (state, action) => {
        state.chainData = action.payload.chainData;
        state.userSettings = action.payload.userSettings ?? null;
        state.activeSmartAccountIndex = action.payload.activeSmartAccountIndex;
      })
      .addCase(initializeEOA.fulfilled, (state, action) => {
        if (action.payload) {
          state.eoaAddress = action.payload.eoaAddress;
          state.eoaPrivateKey = action.payload.eoaPrivateKey;
          state.eoaBalance = action.payload.balance;
          state.provider = action.payload.provider;
          state.isInitialized = true;
        }
      })
      .addCase(initializeSmartWallet.fulfilled, (state, action) => {
        if (action.payload) {
          state.smartAccountAddress = action.payload.smartAccountAddress;
          state.smartAccountBalance = action.payload.smartAccountBalance;
          state.isConnected = true;
        } else {
          log("Failed to initialize smart wallet");
        }
      })
      .addCase(initializeSmartWallet.rejected, (state, action) => {
        log("Smart wallet initialization rejected:", action.error);
      })
      .addCase(addNewSmartAccount.fulfilled, (state, action) => {
        if (action.payload) {
          state.smartAccountAddress = action.payload.smartAccountAddress;
          state.activeSmartAccountIndex = action.payload.newAccountIndex;
        }
      })
      .addCase(addNewSmartAccount.rejected, (state, action) => {
        log("Smart wallet initialization rejected:", action.error);
      })

      .addCase(login.fulfilled, (state) => {
        state.auth.isLoggedIn = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.auth.isLoggedIn = false;
        state.smartAccountAddress = null;
        state.eoaAddress = null;
        state.userSettings = null;
        state.eoaPrivateKey = null;
        state.eoaBalance = null;
        state.smartAccountBalance = null;
        state.isConnected = false;
        state.isInitialized = false;
      });
  },
});

export const { setIsConnected, setUserSettings, setIsLoggedIn } =
  walletSlice.actions;

export default walletSlice.reducer;
