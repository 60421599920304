import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PAGE_TITLE_BUTTON_TYPE, STORAGE_KEYS } from "../../constants/Enums";
import SettingOptions from "./SettingOptions";
import PageTitle from "../../components/PageTitle";
import logoutIcon from "../../assets/logout.svg";

import Modal from "../../components/Modal";
import { removeItemFromStorage, setItemInStorage } from "../../utils/helper";
import MixPanel from "../../utils/MixPanel";
import PageTransitionWrapper from "../../components/PageTransitionWrapper";
import useWallet from "../../lib/store/hooks/useWallet";

const Settings = () => {
  const [showLock, setShowLock] = useState<boolean>(false);
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const location = useLocation();

  const { logout } = useWallet();

  const closeLogOutModal = () => {
    setIsLogOutModalOpen(false);
  };

  const logOut = async () => {
    logout();

    setItemInStorage(STORAGE_KEYS.IS_LOGGED_IN, false);
    await removeItemFromStorage(STORAGE_KEYS.SMART_ACCOUNT);
    navigate("/login");

    MixPanel.reset();
  };

  return (
    <PageTransitionWrapper back={location.state?.back}>
      <div className="w-full h-full bg-primary-bg relative select-none ">
        <PageTitle
          title="Settings"
          leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
          rightButtonIcon={PAGE_TITLE_BUTTON_TYPE.NONE}
        />

        <div className=" absolute top-4 right-2">
          <div className="relative">
            <AnimatePresence>
              {showLock && (
                <motion.p
                  initial={{ translateX: "100%" }}
                  animate={{ translateX: 0 }}
                  exit={{ translateX: "100%" }}
                  transition={{ duration: 0.4 }}
                  className="text-sm bg-card-bg text-white rounded-xl p-1 px-2 absolute bottom-[-2.4em] left-[-4em] whitespace-nowrap"
                >
                  Lock Wallet
                </motion.p>
              )}
            </AnimatePresence>

            <motion.img
              layout
              onHoverStart={() => setShowLock(true)}
              onHoverEnd={() => setShowLock(false)}
              src={logoutIcon}
              alt=""
              className="h-5 w-5 mx-auto cursor-pointer"
              onClick={() => {
                setIsLogOutModalOpen(true);
              }}
            />
          </div>
        </div>

        <Modal
          isOpen={isLogOutModalOpen}
          onCancel={closeLogOutModal}
          message="Do you want to logout"
          actionBtnName="Log Out"
          onRemove={() => logOut()}
        />

        <SettingOptions />
      </div>
    </PageTransitionWrapper>
  );
};

export default Settings;
