import React, { useState } from "react";
import { X } from "react-feather";
import { useNavigate, useLocation } from "react-router-dom";
import PageTransitionWrapper from "../../../components/PageTransitionWrapper";
import GradientBg from "../../../components/GradientBg";
import paste from "../../../assets/paste.svg";
import { log } from "../../../utils/helper";

const ImportWallet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentState = location.state;
  const [mnemonic, setMnemonic] = useState<string>("");

  const handleMnemonicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMnemonic(e.target.value);
  };

  const isMnemonicEmpty = mnemonic.trim() === "";

  // TODO check for a valid mnemonic phrase or not.
  const importWallet = () => {
    navigate("/import-wallet/create-smart-wallet", {
      state: { mnemonic },
    });
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();

      setMnemonic(text);
    } catch (err) {
      log("Failed to read clipboard contents: ", err);
    }
  };

  return (
    <PageTransitionWrapper>
      <GradientBg />
      <div className="relative h-full w-full px-4 flex flex-col">
        <div className="flex justify-between p-2 mt-4">
          <h1 className="text-lg font-semibold tracking-wide">Import wallet</h1>

          <X
            onClick={() => {
              if (
                (currentState && currentState.from === "login") ||
                currentState?.goToLogin ||
                currentState === null
              ) {
                navigate("/login");
              } else {
                navigate("/use-existing-wallet", { state: { back: true } });
              }
            }}
            size={30}
            className="opacity-80 hover:opacity-100"
          />
        </div>
        <p className="text-gray-500  px-2 text-wrap mt-5">
          Securely import your wallet using your recovery phrase.
        </p>

        <div className="mt-20">
          <div className="flex bg-white backdrop:blur-md bg-opacity-10 border rounded-xl justify-between items-center">
            <input
              type="text"
              placeholder="Recovery Phrase"
              value={mnemonic}
              onChange={handleMnemonicChange}
              className="bg-transparent focus:outline-none w-full  pl-3 pr-2 py-5  rounded-s-xl"
            />
            <img
              src={paste}
              alt="paste"
              onClick={handlePaste}
              className="h-5 w-5 mx-2 hover:bg-card-bg2 cursor-pointer"
            />
          </div>
          <span className="text-blue-500 text-sm ml-2 ">
            Where can I find my recovery phrase?
          </span>
        </div>

        <button
          onClick={() => importWallet()}
          disabled={isMnemonicEmpty}
          className={`p-4 w-[90%] absolute left-1/2 translate-x-[-50%] bottom-6 duration-200 rounded-full bg-white text-gray-950 font-semibold tracking-wider mt-3 ${
            isMnemonicEmpty
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-opacity-80"
          }`}
        >
          Import Wallet
        </button>
      </div>
    </PageTransitionWrapper>
  );
};

export default ImportWallet;
