import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Check, Copy, ExternalLink } from "react-feather";
import { supportedChainsState } from "../state/SupportedChainsState";

import { copyToClipboard } from "../utils/helper";
import approved from "../assets/approve-txn.svg";
import failed from "../assets/failed-txn.svg";
import { TransactionDrawerType } from "../constants/Types";
import { TRANSACTION_STATUS } from "../constants/Enums";
import { Button } from "./ui/button";
import useWalletConfig from "../lib/store/hooks/useWalletConfig";

const TransactionDrawer = ({
  isOpen,
  transactionDetails,
}: TransactionDrawerType) => {
  const navigate = useNavigate();
  const { chainID: chainId } = useWalletConfig();
  const [explorer, setExplorer] = useState<string>();
  const [isTransactionHashCopied, setIsTransactionHashCopied] =
    useState<boolean>(false);
  const [supportedChains] = useRecoilState(supportedChainsState);

  useEffect(() => {
    const fetchExplorer = () => {
      const chain = supportedChains.find(
        (_chain) => _chain.chainId === chainId,
      );

      if (chain) {
        setExplorer(chain.explorerUrl);
      }
    };

    fetchExplorer();
  }, [explorer]);

  return (
    <div
      className={`${
        isOpen ? "bottom-0" : " translate-y-full"
      } fixed flex flex-col gap-3 justify-start items-center bottom-0 left-1/2 translate-x-[-50%] w-full h-[300px] bg-secondary-bg border border-gray-300 text-white  rounded-t-3xl  mt-10 px-4 py-5 transition duration-1000  transform z-50 `}
    >
      <div
        className={`flex flex-col gap-3 justify-center items-center transition ease-in duration-3000 delay-700 ${
          transactionDetails.isError ? "h-[216px]" : ""
        }`}
      >
        <img
          className="h-16 transition ease-in duration-3000  "
          src={transactionDetails.isError ? failed : approved}
          alt={`${transactionDetails.isError ? "failed" : "approved"}`}
        />

        <h1 className="text-md font-semibold">
          Status:
          <span
            className={
              transactionDetails.isError ? "text-red-500" : `text-green-500`
            }
          >
            {" "}
            {transactionDetails.isError
              ? TRANSACTION_STATUS.TRANSACTION_FAILED
              : TRANSACTION_STATUS.TRANSACTION_CONFIRMED}
          </span>
        </h1>
      </div>
      <div className="mb-3">
        {!transactionDetails.isError && (
          <div className="flex flex-col gap-3 px-2 justify-center items-center ">
            <div
              onClick={() => {
                copyToClipboard(transactionDetails.transactionHash);
                setIsTransactionHashCopied(true);
              }}
              className="flex justify-center items-center gap-2 rounded-xl px-2 py-2 bg-card-bg2 cursor-pointer text-sm hover:scale-105"
            >
              {!isTransactionHashCopied ? (
                <>
                  <Copy size={18} />
                  <p> Copy Transaction Hash</p>
                </>
              ) : (
                <>
                  <Check color="green" size={18} />
                  <p> Copied</p>
                </>
              )}
            </div>
            <div className=" flex justify-center items-center gap-2  rounded-lg px-2 py-2 w-full text-center bg-card-bg2 hover:scale-105 text-sm">
              <ExternalLink size={17} />
              <a
                href={`${explorer}/tx/${transactionDetails.transactionHash}`}
                target="_blank"
              >
                View on block explorer
              </a>
            </div>
          </div>
        )}
      </div>
      <div className="absolute bottom-2 px-2 py-2 w-full flex justify-evenly gap-3">
        <Button
          onClick={() => {
            navigate("/dashboard");
          }}
          className="w-full bg-card-bg2 hover:bg-primary-bg"
        >
          Dashboard
        </Button>
        <Button
          onClick={() => {
            navigate("/dashboard/activity");
          }}
          className="w-full bg-card-bg2 hover:bg-primary-bg"
        >
          Activity
        </Button>
      </div>
    </div>
  );
};

export default TransactionDrawer;
