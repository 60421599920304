import { Outlet } from "react-router-dom";
import "./index.css";

function PublicLayout() {
  return (
    <div className="w-full h-full">
      <Outlet />
    </div>
  );
}

export default PublicLayout;
