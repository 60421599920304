import { Outlet, useLocation } from "react-router-dom";
import "./index.css";
import BottomNavBar from "../../components/BottomNavBar";

export const PrivateLayout = () => {
  const location = useLocation();
  const route = location.pathname;

  return (
    <div
      className={`h-full w-full bg-primary-bg overflow-hidden relative ${
        route === "/" ||
        route === "/dashboard" ||
        route === "/gasless-dashboard"
      }`}
    >
      <Outlet />
      {route === "/" ||
      route === "/dashboard" ||
      route === "/dashboard/collectibles" ||
      route === "/dashboard/activity" ||
      route === "/dashboard/settings" ? (
        <BottomNavBar />
      ) : (
        <></>
      )}
    </div>
  );
};

export default PrivateLayout;
