import { useEffect, useState } from "react";
import { AdType } from "../../../constants/Types";
import { copyToClipboard } from "../../../utils/helper";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/tooltip";
import { AdvertiserDetailsDefault } from "../../../constants/TypesDefaults";

const Ad = ({ adModal, handleAdComplete, rewardInfo }: AdType) => {
  const [timerRemainingTime, setTimerRemainingTime] = useState(10);

  useEffect(() => {
    let interval: any;

    if (
      adModal &&
      adModal.isOpen &&
      adModal?.adDetails?.advertiserAdUrl !== "" &&
      timerRemainingTime > 0
    ) {
      interval = setInterval(() => {
        setTimerRemainingTime(timerRemainingTime - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [
    adModal,
    adModal.isOpen,
    adModal?.adDetails?.advertiserAdUrl,
    timerRemainingTime,
  ]);

  return (
    <div
      className={` ${
        adModal.isOpen && adModal?.adDetails?.advertiserAdUrl !== ""
          ? ""
          : "hidden"
      } fixed max-w-[600px] top-0 mx-auto w-full h-full flex items-center justify-center bg-primary-bg z-50 bg-opacity-85 text-white`}
    >
      <div className="absolute top-4 right-4  flex justify-center items-center">
        <button
          disabled={!!timerRemainingTime}
          className="border-2 px-4 py-2 rounded-lg hover:bg-accent hover:text-gray-900"
          onClick={() => {
            copyToClipboard(
              adModal?.adDetails?.advertiserWebsite,
              "Advertiser website copied to clipboard!",
            );
            handleAdComplete({
              isOpen: false,
              adDetails: AdvertiserDetailsDefault,
            });
          }}
        >
          {timerRemainingTime ? (
            <>
              {" "}
              Gasless in <span className="font-bold">{timerRemainingTime}</span>
            </>
          ) : (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <span className="font-bold">Skip</span>
                </TooltipTrigger>
                <TooltipContent className="bg-secondary-bg text-white border-black ">
                  <p className="whitespace-nowrap text-sm text-wrap w-[150px]">
                    Advertiser's website will be copied to your clipboard.
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </button>
      </div>
      <video
        className="px-4 w-full mb-10"
        src={adModal?.adDetails?.advertiserAdUrl}
        autoPlay={true}
        controls={false}
      ></video>
      <h1
        className={`leading-9 absolute text-md text-center w-[360px] px-8 truncate ${
          !rewardInfo ? " bottom-[100px] " : " bottom-[70px] "
        }`}
      >
        Powered By
        <br />
        <span className="text-lg truncate max-w-[360px] overflow-hidden font-bold">
          {adModal.adDetails?.advertiserName}
        </span>
        {/* {rewardInfo && (
          <>
            <br />
            <p className="text-[12px]">
              Claim your rewards on{" "}
              <span className="underline">user.getwalletx.com</span>
            </p>
          </>
        )} */}
      </h1>
    </div>
  );
};

export default Ad;
