import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { getAccentColor } from "../utils/helper";
import useWallet from "../lib/store/hooks/useWallet";

import TransactDrawer from "./TransactDrawer";

import homeIcon from "../assets/NavBarIcons/home-icon.svg";
import nftIcon from "../assets/NavBarIcons/nft-icon.svg";
import featuresIcon from "../assets/NavBarIcons/features-icon.svg";
import activityIcon from "../assets/NavBarIcons/activity-icon.svg";
import settingsIcon from "../assets/NavBarIcons/settings-icon.svg";

const BottomNavBar = () => {
  const { chainData } = useWallet();
  const [accentColor, setAccentColor] = useState("#000000");
  const [openTransactDrawer, setOpenTransactDrawer] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState(-1);
  const [containerWidth, setContainerWidth] = useState(0);
  const anchorRef = useRef<HTMLDivElement>(null);

  const fetchTheme = async () => {
    const theme = await getAccentColor(chainData?.chainId);

    setAccentColor(theme);
  };

  useEffect(() => {
    fetchTheme();
  }, [chainData?.chainId]);

  const Menus = [
    {
      name: "Home",
      icon: ` ${homeIcon}`,
      navigate: "/",
    },
    {
      name: "NFT",
      icon: ` ${nftIcon}`,
      navigate: "/dashboard/collectibles",
    },
    {
      name: "Transact",
      icon: ` ${featuresIcon}`,
      navigate: "/dashboard",
    },
    {
      name: "Activity",
      icon: ` ${activityIcon}`,
      navigate: "/dashboard/activity",
    },
    {
      name: "Settings",
      icon: ` ${settingsIcon}`,
      navigate: "/dashboard/settings",
    },
  ];

  useEffect(() => {
    const currentIndex = Menus.findIndex(
      (item) => item.navigate === location.pathname,
    );

    setActive(currentIndex);

    const handleResize = () => {
      setContainerWidth(
        (anchorRef?.current?.offsetParent as HTMLElement)?.offsetWidth || 0,
      );
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]);

  const itemWidth = containerWidth / Menus.length;

  return (
    <>
      <div
        className={`absolute bottom-0 left-1/2 -translate-x-1/2 flex justify-center w-[95%] backdrop-blur-sm bg-primary-bg bg-opacity-100 ${
          openTransactDrawer ? "z-30 backdrop-blur-sm" : ""
        }`}
        style={{
          boxShadow: `0px 15px 5px #000000`,
        }}
      >
        <div
          className={`bg-secondary-bg max-h-[4.4rem] w-full px-2 rounded-xl mb-2 `}
        >
          <motion.div className="flex justify-around relative z-50">
            <AnimatePresence>
              {active !== -1 && (
                <motion.div
                  layout
                  className="border-4 border-primary-bg h-14 w-14 absolute
         -top-5 rounded-3xl -z-10 ml-1"
                  style={{
                    background: accentColor,
                    left: active * itemWidth + itemWidth / 2 - 32,
                  }}
                  initial={{ y: -10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 100, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <div
                    className="w-3.5 h-3.5 bg-transparent absolute top-4 -left-[18px] 
          rounded-tr-[9px] "
                  ></div>

                  <div
                    className="w-3.5 h-3.5 bg-transparent absolute top-4 -right-[18px] 
          rounded-tl-[11px] "
                  ></div>
                </motion.div>
              )}
            </AnimatePresence>
            {Menus.map((menu, i) => (
              <div
                onClick={() => {
                  if (menu.name !== "Transact") {
                    setOpenTransactDrawer(false);
                    navigate(menu.navigate);
                  } else {
                    setOpenTransactDrawer(!openTransactDrawer);
                  }
                }}
                key={i}
                ref={i === 0 ? anchorRef : undefined}
                className="w-16"
              >
                <div
                  className="flex flex-col text-center pt-2"
                  onClick={() => setActive(i)}
                >
                  <div
                    className={` cursor-pointer duration-500 flex items-center justify-center ${
                      i === active && "-mt-3  text-white"
                    }`}
                  >
                    <img className="h-7" alt="menu-item" src={menu.icon} />
                  </div>
                  <div
                    className={` text-sm ${
                      active === i
                        ? "translate-y-[10px] duration-1000 opacity-100   "
                        : "opacity-0 translate-y-10"
                    } `}
                  >
                    {menu.name}
                  </div>
                </div>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
      <TransactDrawer isOpen={openTransactDrawer} />
    </>
  );
};

export default BottomNavBar;
