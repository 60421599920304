import { useState } from "react";
import Marquee from "react-fast-marquee";

import { checkExtensionApp } from "../../utils/helper";

const InfiniteMarquee = () => {
  const [isPaused, setIsPaused] = useState(false);
  const marqueText = (
    <div className="flex items-center font-sans text-sm px-4">
      <span className="text-red-700">This is a test app URL. Please go to</span>
      <a
        href="https://app.getwalletx.com"
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
        className="text-blue-500 hover:text-blue-800 underline mx-2 font-medium"
      >
        app.getwalletx.com
      </a>
      <span className="text-red-700">to access the main app.</span>
    </div>
  );

  const xtensionMarqueText = (
    <div className="flex items-center font-sans text-sm px-4">
      <span className="text-red-700">
        This is the Beta App. The experimental features will be release here
        first.
      </span>
    </div>
  );

  return (
    <div
      className="bg-gradient-to-r bg-white shadow-sm py-1 z-50 absolute"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <Marquee
        gradient={false}
        speed={40}
        pauseOnHover={true}
        play={!isPaused}
        className="overflow-hidden"
      >
        {checkExtensionApp() ? xtensionMarqueText : marqueText}
      </Marquee>
    </div>
  );
};

export default InfiniteMarquee;
