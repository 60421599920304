import * as Config from "../config/env";

/* eslint-disable no-shadow */

export enum ENVIRONMENT {
  PRODUCTION = "production",
  TEST = "test",
  DEVELOPMENT = "development",
}

export enum ASSET_TYPE {
  TOKEN = "TOKEN",
  NFT = "NFT",
}

export const NATIVE_ADDRESS = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
export const NATIVE_MATIC_ADDRESS =
  "0x0000000000000000000000000000000000001010";

export enum TAB_LIST {
  // Bottom navbar or tokens
  TOKEN = "TOKEN",
  NFT = "NFT",
  ACTIVITY = "ACTIVITY",
  // Network type selection tab
  MAINNET = "MAINNET",
  TESTNET = "TESTNET",
  // Account selection Tabs
  EOA = "EOA",
  SCW = "SCW",
}

export enum TRANSACTION_ERROR {
  TRANSACTION_CANCELLED = "TRANSACTION_CANCELLED",
  TRANSACTION_FAILED = "TRANSACTION_FAILED",
}

export enum TRANSACTION_STATUS {
  TRANSACTION_CANCELLED = "CANCELLED",
  TRANSACTION_FAILED = "FAILED",
  TRANSACTION_CONFIRMED = "CONFIRMED",
  TRANSACTION_SUCCESS = "SUCCESS",
}

export const DEFAULT_CHAIN_ID = Config.DEFAULT_NETWORK.CHAIN_ID;

export enum REGISTER {
  REGISTER_NEW = "REGISTER_NEW",
  IMPORT = "IMPORT",
  DEFAULT = "DEFAULT",
}

export enum PAGE_TITLE_BUTTON_TYPE {
  ARROW_LEFT = "ArrowLeft",
  ARROW_RIGHT = "ArrowRight",
  NONE = "None",
  HB = "Hamburger",
  X = "X",
}

export enum INPUT_VALUE {
  NAME = "Name",
  ADDRESS = "Address",
  VALUE = "Value",
  MESSAGE = "Message",
}

export enum DAPP_INTERACTION_METHODS {
  /** CUSTOM METHODS */
  CHANGE_ADDRESS = "changeAddress",

  /** ETH METHODS */

  // disconnect wallet
  DISCONNECT = "disconnect",

  // Message signing
  ETH_SIGN = "eth_sign",
  PERSONAL_SIGN = "personal_sign",
  ETH_SIGN_TYPED_DATA_V4 = "eth_signTypedData_v4",
}

export enum MIXPANEL_KEY {
  // LOGIN
  Login_Successful = "Login_Successful",
  Login_Error = "Login_Error",

  // IMPORT
  Successful_Import = "Successful_Import",

  // REGISTER
  Register_Successful = "Register_Successful",

  // IMPORT TOKENS
  Import_Token_Successful = "Import_Token_Successful",
  Import_Token_Error = "Import_Token_Error",

  // ADVERTISEMENT
  Advertisement_Started = "Advertisement_Started",
  Advertisement_Served = "Advertisement_Served",

  // CHAIN
  Chains_Switched = "Chains_Switched",

  // MNEMONIC
  Export_Mnemonic_Successful = "Export_Mnemonic_Successful",

  // TRANSACTIONS
  Transaction_Initiated = "Transaction_Initiated",
  Transaction_Broadcasted = "Transaction_Broadcasted",
  Transaction_Completed = "Transaction_Completed",

  // DAPP TRANSACTIONS
  Dapp_Transaction_Initiated = "Dapp_Transaction_Initiated",
  Dapp_Transaction_Broadcasted = "Dapp_Transaction_Broadcasted",
  Dapp_Transaction_Completed = "Dapp_Transaction_Completed",

  // BACKEND UPDATE
  Backend_Update_Failed = "Backend_Update_Failed",
}

export enum ERROR_EVENTS {
  SMART_WALLET_ERROR = "SMART_WALLET_ERROR",
  INTERNAL_ERROR = "INTERNAL_ERROR",
}

export enum CUSTOM_EVENTS {
  ACCOUNT_CHANGE = "ACCOUNT_CHANGE",
}

export enum ERROR_MESSAGE {
  INCORRECT_RPC = "Incorrect RPC URL",
  DAPP_NETWORK_ERROR = "Network error, try again later.",
}

export enum ERROR_TOAST_MESSAGES {
  NETWORK_ERROR_TOAST = "Network not active, switching back to the previous chain.",
  NETWORK_ACTIVATE_ERROR_TOAST = "Error while connecting to chain1, switching back to the chain2.", // correct error message in helpers:getNetworkErrorMessage()
}

export enum ASSET_SELECTION_TASK {
  TRANSACTION = "TRANSACTION",
  SWAP_BRIDGE = "SWAP_BRIDGE",
}

export enum SWAP_ASSET_TYPE {
  FROM_TOKEN = "FROM_TOKEN",
  TO_TOKEN = "TO_TOKEN",
}

export enum STORAGE_NAME {
  LOCAL_STORAGE = "localStorage",
  SESSION_STORAGE = "sessionStorage",
}

export enum STORAGE_KEYS {
  SIGNER = "signer",
  DEVICES = "devices",
  LAST_ACTIVE = "lastActive",
  IS_LOGGED_IN = "isLoggedIn",
  NETWORK = "network",
  OLD_NETWORK = "oldNetwork",
  SMART_ACCOUNT = "smartAccount",
  DEVELOPER_MODE = "DeveloperMode",
  USER_SETTINGS = "usersSettings",
  PREVIOUS_TRANSACTIONS = "previousTransactions",
  APP_VERSION = "version",
  DAPP_CONNECTIONS = "dappConnections",
  WC_CONNECT = "walletConnectKey",
  QR_SCAN_TRANSACTION = "qrScanTransaction",
  QR_SCAN_TRANSACTION_ADDRESS = "qrScanTransactionAddress",
  WC_READ_STATUS = "walletConnectKeyRead",
  BUY_SELL = "buySell",
  SAVED_USERS = "savedUsers",
}

export enum DEVICE_AUTH_TYPE {
  NONE = "NONE",
  PASSWORD = "PASSWORD",
  PASSKEY = "PASSKEY",
  BOTH = "BOTH", // Password and Passkey both are enabled
}

export enum WALLET_TYPE {
  GENERATED = "GENERATED",
  IMPORTED = "IMPORTED",
  LEDGER = "LEDGER",
  WATCH = "WATCH", // IT WILL BE USED FOR WATCHING THE ACTIVITY OF AN ACCOUNT IN FUTURE WHEN WE ADD THE WALLET.
}

export enum TRANSACTION_TYPE {
  NONE = "NONE",
  SIGN = "SIGN",
  CONNECT = "CONNECT",
  APPROVE_TRANSACTION = "APPROVE_TRANSACTION",
}

export enum TIME {
  IMMEDIATELY = "IMMEDIATELY",
  MINUTES_5 = "MINUTES_5",
  MINUTES_10 = "MINUTES_10",
  MINUTES_15 = "MINUTES_15",
  MINUTES_30 = "MINUTES_30",
  HOUR_1 = "HOUR_1",
  HOUR_3 = "HOUR_3",
  HOUR_6 = "HOUR_6",
  HOUR_8 = "HOUR_8",
  HOUR_12 = "HOUR_12",
  HOUR_24 = "HOUR_24",
  NONE = "NONE",
}

export const LOCK_TIME = {
  [TIME.IMMEDIATELY]: {
    id: 1,
    time: "immediately",
    minutes: 0,
  },
  [TIME.MINUTES_5]: {
    id: 2,
    time: "5 minutes",
    minutes: 5,
  },
  [TIME.MINUTES_10]: {
    id: 3,
    time: "10 minutes",
    minutes: 10,
  },
  [TIME.MINUTES_15]: {
    id: 4,
    time: "15 minutes",
    minutes: 15,
  },
  [TIME.MINUTES_30]: {
    id: 5,
    time: "30 minutes",
    minutes: 30,
  },
  [TIME.HOUR_1]: {
    id: 6,
    time: "1 hour",
    minutes: 60,
  },
  [TIME.HOUR_3]: {
    id: 7,
    time: "3 hours",
    minutes: 180,
  },
  [TIME.HOUR_6]: {
    id: 8,
    time: "6 hours",
    minutes: 360,
  },
  [TIME.HOUR_8]: {
    id: 9,
    time: "8 hours",
    minutes: 480,
  },
  [TIME.HOUR_12]: {
    id: 10,
    time: "12 hours",
    minutes: 720,
  },
  [TIME.HOUR_24]: {
    id: 11,
    time: "24 hours",
    minutes: 1440,
  },
  [TIME.NONE]: {
    id: 12,
    time: "None",
    minutes: -1,
  },
};

export enum THEMECOLOR {
  BLAZE_RED = "#dc2626",
  ORANGE_EMBER = "#f97316",
  GOLDRUSH_YELLOW = "#fcd34d",
  GREEN_SURGE = "#4ade80",
  AQUA_CYAN = "#22d3ee",
  BLUE_WAVE = "#3b82f6",
  INDIGO_ABYSS = "#6366f1",
  VIOLET_VORTEX = "#8b5cf6",
  PURPLE_MYSTIC = "#a855f7",
  FUCHSIA_FIZZ = "#d946ef",
  PINK_PULSE = "#f472b6",
  LIME_ZEST = "#bef264",
  DEFAULT = "default",
}

export enum NFT_IMPORT_MODAL_FIELD_ERROR {
  NULL,
  BOTH,
  ADDRESS,
  TOKEN_ID,
}

export enum NFT_IMPORT_MODAL_RESPONSE_ERROR {
  NULL,
  TRY_AGAIN = "Error while importing NFT, try again later!",
  NOT_FOUND = `NFT not found on this network.<br>Please verify the address and tokenId.`,
  INVALID_OWNER = "This NFT doesn't belongs to you!",
}

export enum TRANSACTION_APP_TYPE {
  IN_APP,
  WALLET_CONNECT,
  DAPP_INTERACTION,
}

export enum GAS_FEE_TYPE {
  COIN = "COIN",
  TOKEN = "TOKEN",
  GASLESS = "GASLESS",
}
