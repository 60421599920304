import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import toast from "react-hot-toast";
import {
  BiconomySmartAccountV2,
  IHybridPaymaster,
  PaymasterMode,
  SponsorUserOperationDto,
  UserOperationStruct,
} from "@biconomy/account";
import { PaymasterFeeQuote } from "@biconomy/paymaster";
import { v4 } from "uuid";
import { AnimatePresence, easeOut, motion } from "framer-motion";
import GasToken from "./GasToken";
import Gasless from "./Gasless";
import GasTokenSelectionDrawer from "../GasTokenSelectionDrawer";
import {
  SelectedTokenForGasType,
  GasManagementType,
  TransferStateType,
  RawTransactionObjectType,
  GasStateType,
  AdModalType,
  AdvertiserDetails,
} from "../../constants/Types";
import {
  constructTransactionData,
  getRawTransactionObject,
  constructFinalUserOpForGasless,
} from "../../utils/helper";
import MixPanel from "../../utils/MixPanel";
import { supportedChainsState } from "../../state/SupportedChainsState";
import { Button } from "../../components/ui/button";
import fingerPrint from "../../assets/biometric-identification.svg";
import WatchAds2 from "../../assets/play-video.svg";
import * as config from "../../config/env";
import { MIXPANEL_KEY } from "../../constants/Enums";
import { getAdvertiserDetails } from "../../utils/api";
import { AdvertiserDetailsDefault } from "../../constants/TypesDefaults";

import gaslessIcon from "../../assets/illustrations/gasless.png";
import dappConnection from "../../assets/illustrations/dapp-connection.png";
import multiChain from "../../assets/illustrations/multiChain.png";
import smartWallets from "../../assets/illustrations/smart-wallets.png";
import stateOfArt from "../../assets/illustrations/state-of-art.png";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

const GasManagement = ({
  transaction,
  selectedTokenForGas,
  setSelectedTokenForGas,
  isDappTransaction,
  transferDataList,
  partialTransaction,
  sendGaslessTransaction,
  sendBatchTransaction,
  nativeAssetGas,

  // remove shouldShowAd if want to always want gasless without ad
  shouldShowAd,
  gasInUSD,
  authError,
  setAdURL,
}: GasManagementType) => {
  const [chains] = useRecoilState(supportedChainsState);
  const [adDetails, setAdDetails] = useState<AdvertiserDetails>(
    AdvertiserDetailsDefault,
  );
  const [isGaslessActive, setIsGaslessActive] = useState<boolean>(true);
  const [adModal, setAdModal] = useState<AdModalType>({
    isOpen: false,
    adDetails: AdvertiserDetailsDefault,
  });
  const [isAdComplete, setIsAdComplete] = useState<boolean>(false);
  const [isGaslessDisabled, setIsGaslessDisabled] = useState<boolean>(false);
  const [isGasDrawerVisible, setIsGasDrawerVisible] = useState<boolean>(false);
  const [gasSupportedTokens, setGasSupportedTokens] = useState<
    GasStateType[] | null
  >([]);
  const {
    getSmartAccountProvider,
    smartAccountAddress,
    chainID,
    userSettings,
  } = useWalletConfig();

  const { isERC20GasPaymentSupported, isGaslessEnabled } = chains.filter(
    (d) => d.chainId === chainID,
  )[0];

  const checkSupportForErc20Payment = () =>
    userSettings?.isEoaSelected ? false : isERC20GasPaymentSupported;

  useEffect(() => {
    async function adData() {
      const data: AdvertiserDetails = await getAdvertiserDetails();

      setAdURL(data.advertiserAdUrl);
      setAdDetails(data);
    }

    adData();
  }, []);

  const buttonDisableUntilNativeGas = (): boolean => !Number(nativeAssetGas);

  const openGasSelectionDrawer = () => {
    setIsGasDrawerVisible(true);
  };
  const closeGasSelectionDrawer = () => {
    setIsGasDrawerVisible(false);
  };

  const updateSelectedTokenForGas = ({
    icon,
    tokenName,
    tokenSymbol,
    tokenAddress,
    tokenGasValue,
  }: SelectedTokenForGasType) => {
    setSelectedTokenForGas({
      icon,
      tokenName,
      tokenSymbol,
      tokenAddress,
      tokenGasValue,
    });
    closeGasSelectionDrawer();
  };

  useEffect(() => {
    if (isGaslessDisabled) setIsGaslessActive(false);
  }, [isGaslessDisabled]);

  const supportedTokensForGas = async (
    transferData: TransferStateType[] | any[],
  ) => {
    if (userSettings?.isEoaSelected || isGaslessEnabled === false) {
      setIsGaslessDisabled(true);
      setGasSupportedTokens(null);
      return;
    }

    try {
      const rawTransaction: RawTransactionObjectType[] = [];
      const dappRawTransactions: any[] = [];

      if (isDappTransaction) {
        transferData.forEach((data: any) => {
          const obj: any = {};

          obj.to = data.to;
          obj.value = data.value;
          obj.from = data.from;
          obj.data = data.data;

          dappRawTransactions.push(obj);
        });
      } else {
        transferData.forEach((data: TransferStateType) => {
          const obj = getRawTransactionObject(data, smartAccountAddress);

          obj.forEach((element: any) => {
            rawTransaction.push(element);
          });
        });
      }

      let txns = dappRawTransactions;

      if (!isDappTransaction) {
        txns = constructTransactionData(rawTransaction);
      }

      // TODO please check if the types are proper or not
      const smartAccountProvider = await getSmartAccountProvider();
      const userOp = (await smartAccountProvider?.buildUserOp(
        txns,
      )) as Partial<UserOperationStruct>;
      const paymaster =
        smartAccountProvider?.paymaster as IHybridPaymaster<SponsorUserOperationDto>;

      const feeQuotesResponse = await paymaster.getPaymasterFeeQuotesOrData(
        userOp,
        { mode: PaymasterMode.ERC20, tokenList: [] },
      );

      const supportedTokens: GasStateType[] = [];

      if (feeQuotesResponse.feeQuotes) {
        feeQuotesResponse.feeQuotes.map((token: PaymasterFeeQuote) => {
          const tokenObj: GasStateType = {
            tokenUID: v4(),
            tokenLogo: token.logoUrl || "",
            tokenName: token.symbol,
            tokenSymbol: token.symbol,
            tokenAddress: token.tokenAddress,
            tokenBalance: 0,
            tokenGas: token.maxGasFee,
            tokenGasValue: token.maxGasFeeUSD || 0,
          };

          return supportedTokens.push(tokenObj);
        });
      }

      setGasSupportedTokens(supportedTokens);

      try {
        await paymaster.getPaymasterFeeQuotesOrData(userOp, {
          mode: PaymasterMode.SPONSORED,
          tokenList: [],
        });
      } catch (e: any) {
        toast.error(
          "Unable to process gasless transaction at this moment. Please proceed with native transaction.",
        );

        setIsGaslessDisabled(true);
      }
    } catch (e: any) {
      setGasSupportedTokens(null);
    }
  };

  useEffect(() => {
    if (Number(gasInUSD) > 5) {
      toast.error(
        "Unable to process gasless transaction at this moment. Please proceed with native transaction.",
      );

      setIsGaslessDisabled(true);
    }

    if (isGaslessEnabled === false) {
      setIsGaslessDisabled(true);
    }

    supportedTokensForGas(transferDataList);
  }, [transferDataList.length]);

  const processGaslessTransaction = async () => {
    if (userSettings?.isEoaSelected) return;

    const smartAccountProvider: BiconomySmartAccountV2 | null =
      await getSmartAccountProvider();

    if (!smartAccountProvider)
      throw new Error(
        "Unable to initialize SC provider while processing gasless transaction",
      );

    const finalUserOp = await constructFinalUserOpForGasless(
      smartAccountProvider,
      partialTransaction,
    );

    sendGaslessTransaction(finalUserOp);
  };

  const toggleGaslessSwitch = () => {
    if (!isGaslessDisabled) setIsGaslessActive(!isGaslessActive);
  };

  const handleAdComplete = (param: AdModalType) => {
    MixPanel.track(MIXPANEL_KEY.Advertisement_Served, {
      transactionId: transaction.transactionId,
      adCompany: adDetails.advertiserName,
      chainID,
      transactions: transferDataList,
    });
    setIsAdComplete(true);
    setAdModal(param);
  };

  const dappConfirmButton = (isGasless: boolean) => (
    <Button
      disabled={
        buttonDisableUntilNativeGas() ||
        (transaction && transaction.transactionInProcess)
      }
      onClick={async () => {
        if (isGasless) await processGaslessTransaction();
        else sendBatchTransaction();
      }}
      variant={"tsxButton"}
      size={"tsx"}
      style={{ position: "relative" }}
    >
      {"Confirm "}
    </Button>
  );

  const xtensionApproveButton = (isGasless: boolean) => (
    <Button
      disabled={
        buttonDisableUntilNativeGas() ||
        (transaction && transaction.transactionInProcess)
      }
      onClick={async () => {
        if (isGasless) await processGaslessTransaction();
        else sendBatchTransaction();
      }}
      variant={"tsxButton"}
      size={"tsx"}
      style={{ position: "relative" }}
    >
      {authError || "Approve Transaction "}

      <img className="h-7" src={fingerPrint} alt="fingerPrint" />
    </Button>
  );

  /** if dont want to show watch ad button then comment this out.
   * When it will be required, uncomment this and uncomment proceedTransactionButton() accordingly.
   */

  const watchAdButton = () => (
    <Button
      disabled={
        buttonDisableUntilNativeGas() ||
        (transaction && transaction.transactionInProcess)
      }
      onClick={() => {
        setAdModal({
          isOpen: true,
          adDetails: {
            advertiserAdUrl: adDetails.advertiserAdUrl || config.DUMMY_AD_URL,
            advertiserName: adDetails.advertiserName || config.DUMMY_AD_NAME,
            advertiserWebsite:
              adDetails.advertiserWebsite || config.DUMMY_AD_WEBSITE,
            advertiserChains:
              adDetails.advertiserChains && adDetails.advertiserChains.length
                ? adDetails.advertiserChains
                : config.DUMMY_AD_CHAINS,
          },
        });
        MixPanel.track(MIXPANEL_KEY.Advertisement_Started, {
          transactionId: transaction.transactionId,
          adCompany: adDetails.advertiserName,
          chainID,
          transactions: transferDataList,
        });
      }}
      variant={"tsxButton"}
      size={"tsx"}
    >
      Watch Ad
      <img className="h-8" src={WatchAds2} alt="fingerPrint" />
    </Button>
  );

  // if dont want to show watch ad button and always want gasless then comment this out.

  // const proceedTransactionButton = () => {
  //   let isGasless = true;

  //   if (Boolean(userSettings?.isEoaSelected) || isGaslessDisabled)
  //     isGasless = false;

  //   if (isDappTransaction) return dappConfirmButton(isGasless);

  //   return xtensionApproveButton(isGasless);
  // };

  const proceedTransactionButton = () => {
    if (isAdComplete) {
      if (isDappTransaction) return dappConfirmButton(true);

      return xtensionApproveButton(true);
    }

    if (isGaslessActive)
      if (!shouldShowAd || (shouldShowAd && shouldShowAd())) {
        return watchAdButton();
      } else {
        if (isDappTransaction) return dappConfirmButton(true);

        return xtensionApproveButton(true);
      }

    if (isDappTransaction) return dappConfirmButton(false);

    return xtensionApproveButton(false);
  };

  const transactionPanel = () => {
    if (transaction && transaction.transactionInProcess === true)
      return (
        <div
          style={{
            width: "315px",
            margin: "15px auto",
            border: "1px solid grey",
            borderRadius: "8px",
          }}
        >
          {/* progress bar */}
          <div
            style={{
              top: 0,
              left: 0,
              width: `${transaction && transaction.progressBar}%`,
              height: "20px",
              backgroundColor: "#ffffff",
              transition: "width 0.1s ease-in-out",
              borderRadius: "8px",
            }}
            className="flex justify-end items-center overflow-hidden"
          >
            <div className="flex flex-row-reverse">
              <motion.div
                animate={{
                  translateX: transaction.progressBar < 20 ? [0, -20] : 0,
                }}
                transition={{
                  delay: 0.4,
                  duration: 0.3,
                  ease: easeOut,
                  repeat: Infinity,
                  repeatType: "mirror",
                }}
              >
                <img src={gaslessIcon} className="h-5 w-5" />
              </motion.div>
              {transaction.progressBar > 20 && (
                <motion.div
                  animate={{
                    translateX: transaction.progressBar < 50 ? [0, -20] : 0,
                  }}
                  transition={{
                    delay: 0.4,
                    duration: 0.3,
                    ease: easeOut,
                    repeat: Infinity,
                    repeatType: "mirror",
                  }}
                >
                  <motion.img
                    initial={{ translateX: -60 }}
                    animate={{ translateX: 0 }}
                    transition={{ duration: 0.3 }}
                    src={dappConnection}
                    className="h-5 w-5"
                  />
                </motion.div>
              )}
              {transaction.progressBar > 50 && (
                <motion.div
                  animate={{
                    translateX: transaction.progressBar < 70 ? [0, -20] : 0,
                  }}
                  transition={{
                    delay: 0.4,
                    duration: 0.3,
                    ease: easeOut,
                    repeat: Infinity,
                    repeatType: "mirror",
                  }}
                >
                  <motion.img
                    initial={{ translateX: -100 }}
                    animate={{ translateX: 0 }}
                    transition={{ duration: 0.3 }}
                    src={multiChain}
                    className="h-5 w-5"
                  />
                </motion.div>
              )}
              {transaction.progressBar > 70 && (
                <motion.div
                  animate={{
                    translateX: transaction.progressBar < 80 ? [0, -20] : 0,
                  }}
                  transition={{
                    delay: 0.4,
                    duration: 0.3,
                    ease: easeOut,
                    repeat: Infinity,
                    repeatType: "mirror",
                  }}
                >
                  <motion.img
                    initial={{ translateX: -140 }}
                    animate={{ translateX: 0 }}
                    transition={{ duration: 0.3 }}
                    src={smartWallets}
                    className="h-5 w-5"
                  />
                </motion.div>
              )}
              {transaction.progressBar > 80 && (
                <motion.div
                  animate={{ translateX: [0, -20] }}
                  transition={{
                    delay: 0.4,
                    duration: 0.3,
                    ease: easeOut,
                    repeat: Infinity,
                    repeatType: "mirror",
                  }}
                >
                  <motion.img
                    initial={{ translateX: -200 }}
                    animate={{ translateX: 0 }}
                    transition={{ duration: 0.3 }}
                    src={stateOfArt}
                    className="h-5 w-5"
                  />
                </motion.div>
              )}
            </div>
          </div>
        </div>
      );

    if (isDappTransaction)
      return (
        <div className="flex pt-0 p-4 pb-2 gap-4">
          <Button
            disabled={
              buttonDisableUntilNativeGas() ||
              (transaction && transaction.transactionInProcess)
            }
            onClick={() => sendBatchTransaction(true)}
            variant={"tsxButton"}
            size={"tsx"}
            style={{ position: "relative" }}
          >
            {"Reject "}
          </Button>

          {proceedTransactionButton()}
        </div>
      );

    return proceedTransactionButton();
  };

  return (
    <>
      <div className=" w-full h-full flex flex-col justify-center items-center  ">
        <motion.div
          layout
          className={`flex flex-col w-full h-full bottom-0 justify-end items-center transition duration-500`}
          animate={
            isGaslessActive || (transaction && transaction.transactionInProcess)
              ? { height: "auto" }
              : { height: 0 }
          }
          transition={{ duration: 0.3 }}
        >
          <Gasless
            isEoa={Boolean(userSettings?.isEoaSelected) || isGaslessDisabled}
            isGaslessDisabled={isGaslessDisabled}
            isGaslessActive={isGaslessActive}
            toggleGaslessSwitch={toggleGaslessSwitch}
            adModal={adModal}
            handleAdComplete={handleAdComplete}
            rewardInfo={adDetails?.advertiserChains?.includes(Number(chainID))}
          />
          <AnimatePresence>
            {!isGaslessActive && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="w-full flex justify-center"
              >
                <GasToken
                  transactionInProcess={
                    transaction && transaction.transactionInProcess
                  }
                  openGasSelectionDrawer={openGasSelectionDrawer}
                  selectedTokenForGas={selectedTokenForGas}
                  isERC20TokenGasSupported={checkSupportForErc20Payment()}
                  nativeAssetGas={nativeAssetGas}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        {checkSupportForErc20Payment() && (
          <GasTokenSelectionDrawer
            isOpen={isGasDrawerVisible}
            updateGasToken={updateSelectedTokenForGas}
            selectedTokenForGas={selectedTokenForGas}
            onClose={closeGasSelectionDrawer}
            gasSupportedTokens={gasSupportedTokens || null}
            nativeAssetGas={nativeAssetGas}
          />
        )}
      </div>
      <>{transactionPanel()}</>
    </>
  );
};

export default GasManagement;
