import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRight, Search } from "react-feather";
import toast from "react-hot-toast";
import {
  checkExtensionApp,
  getExtensionURL,
  getUserSettingsData,
  log,
} from "../../utils/helper";
import { settingOptions } from "./settingOptionsData";
import Web3AvatarComponent from "../../components/Web3AvatarComponent";
import { UserSettingsType } from "../../constants/Types";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";
import useWallet from "../../lib/store/hooks/useWallet";

const SettingOptions = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [name, setName] = useState<string>("");
  const { smartAccountAddress, eoaAddress } = useWalletConfig();
  const { userSettings } = useWallet();

  const fetchName = async () => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();
      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        const { walletName } = usersSettings[userIndex];

        setName(walletName);
      }
    } catch (error) {
      log("Error fetching address book entries:", error);
    }
  };

  const renderSettingOptions = (renderOptions: any) =>
    renderOptions.map((options: any, index: number) => (
      <>
        {options.hasFunction === true &&
        options.shouldExpandView &&
        !checkExtensionApp() ? (
          <> </>
        ) : (
          <div
            key={index}
            onClick={() => {
              if (
                options.hasFunction === true &&
                options.shouldExpandView &&
                checkExtensionApp()
              ) {
                window.open(getExtensionURL(), "_black");
              } else if (options.navigateTo === "" && !options.shouldNavigate) {
                toast("Coming Soon", {
                  icon: "🔥",
                });
              } else navigate(options.navigateTo);
            }}
            className={`w-full flex justify-between gap-1 text-gray-100 hover:bg-card-bg cursor-pointer rounded-lg px-2 py-3`}
          >
            <div className="w-[7%] flex justify-center items-center ml-1">
              {typeof options.logo !== "string" ? (
                <>{options.logo}</>
              ) : (
                <img src={options.logo} alt="" />
              )}
            </div>
            <div className="w-[80%] px-2 my-auto">
              <h1 className="font-medium text-md">{options.title}</h1>
              <p className="text-xs pr-1 text-gray-300">
                {options.description}
              </p>
            </div>
            <div className="flex justify-end items-center w-[13%] text-xl font-semibold text-gray-300">
              <ChevronRight />
            </div>
          </div>
        )}
      </>
    ));

  const searchSettingOptions = () => {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    const matchedOptions: any = [];

    if (searchValue === "") {
      return renderSettingOptions(settingOptions);
    }

    settingOptions.forEach((option) => {
      if (
        option.title.toLowerCase().includes(lowerCaseSearchValue) ||
        (option.description &&
          option.description.toLowerCase().includes(lowerCaseSearchValue))
      ) {
        matchedOptions.push(option);
      }
    });

    if (matchedOptions.length === 0) {
      return renderSettingOptions(settingOptions);
    }

    return renderSettingOptions(matchedOptions);
  };

  useEffect(() => {
    fetchName();
  }, [eoaAddress]);

  useEffect(() => {
    searchSettingOptions();
  }, [searchValue]);

  return (
    <div className="w-full h-full max-h-[85%] flex flex-col pb-16 overflow-y-scroll ">
      <div className="flex items-center border border-gray-300 rounded-lg mb-2 p-2 text-sm sticky top-0 bg-primary-bg">
        <button className="min-w-fit  pr-1 opacity-60">
          <Search className="h-5 mx-auto my-auto" />
        </button>
        <input
          type="text"
          placeholder="Search..."
          className="w-full focus:outline-none pl-1 bg-transparent"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>

      <div
        className="flex gap-1 items-center p-2 py-3  my-2 bg-card-bg hover:bg-card-bg2 rounded-xl cursor-pointer"
        onClick={() => navigate("/setting/edit-account")}
      >
        <Web3AvatarComponent
          address={
            userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress
          }
          className=" min-w-10 min-h-10 aspect-square mr-1"
        />
        <div className="flex-grow">
          <p>{name}</p>
          <p className="text-xs">
            Manage Accounts, Export Mnemonic, Theme and more
          </p>
        </div>
        <div className=" flex justify-end items-center w-[13%] text-xl font-semibold text-gray-300">
          <ChevronRight />
        </div>
      </div>

      <div className="px-2">{searchSettingOptions()}</div>
    </div>
  );
};

export default SettingOptions;
