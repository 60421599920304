import { useEffect, useState } from "react";
import { ethers, BigNumber } from "ethers";
import { useRecoilState } from "recoil";
import { BiconomySmartAccountV2, UserOperationStruct } from "@biconomy/account";
import { v4 as uuidv4 } from "uuid";
import MixPanel from "../../../utils/MixPanel";
import Store from "../../../dapp-connection/js/components/StoreComponent";
import EthProvider from "../../../dapp-connection/js/services/EthProvider";
import TransactionReview from "../components/TransactionReview";
import {
  checkIsNativeToken,
  constructFinalUserOp,
  getItemFromStorage,
  initialSelectedTokenForGas,
  log,
  getNativeAssetGas,
  isAddressSame,
  getExplorerTxUrl,
  formatDateFromTimestamp,
  formatUnixTimestamp,
  setTransactionDataInLocal,
  getUserSettingsData,
  getUSDValue,
  getNativeAssetGasForEoa,
  getEoaSigner,
  checkIsMainnet,
} from "../../../utils/helper";
import { SelectedTokenForGasDefault } from "../../../constants/TypesDefaults";
import {
  SelectedTokenForGasType,
  TransactionData,
  UserSettingsType,
} from "../../../constants/Types";
import { validateBiometric } from "../../../hooks/functional-hooks";
import usePasswordVerification from "../../../hooks/functional-hooks/usePasswordVerification";
import {
  ERROR_EVENTS,
  ERROR_MESSAGE,
  MIXPANEL_KEY,
  STORAGE_KEYS,
  TRANSACTION_STATUS,
  DEVICE_AUTH_TYPE,
  GAS_FEE_TYPE,
} from "../../../constants/Enums";
import { supportedChainsState } from "../../../state/SupportedChainsState";
import * as Config from "../../../config/env";
import useWalletConfig from "../../../lib/store/hooks/useWalletConfig";
import { saveCompletedTxn } from "../../../utils/api";

export default function Transaction() {
  const [isLoading, setIsLoading] = useState(true);
  const [gasInUSD, setGasInUSD] = useState<number>(0);
  const [nativeAssetInUSD, setNativeAssetInUSD] = useState<number>(0);
  const [supportedChains] = useRecoilState(supportedChainsState);
  const [transactionId, setTransactionId] = useState<string>("");
  const [partialTransaction, setPartialTransaction] = useState<
    Partial<UserOperationStruct>
  >({ sender: "" });
  const [smartWalletAddress, setSmartWalletAddress] = useState<string>("");
  const [transactionData, setTransactionData] = useState<any>({});
  const [credId, setCredId] = useState<string>("");

  const biometricAuth = validateBiometric();
  const [transactionInProcess, setTransactionInProcess] =
    useState<boolean>(false);
  const [walletAuthType, setWalletAuthType] = useState<DEVICE_AUTH_TYPE | null>(
    null,
  );
  const [activeSmartWallet, setActiveSmartWallet] =
    useState<UserSettingsType | null>(null);
  const {
    getSmartAccountProvider,
    isInitialized,
    smartAccountAddress,
    init,
    provider,
    eoaAddress,
    userSettings,
    eoaPrivateKey,
  } = useWalletConfig();
  const chainIDFromStorage = getItemFromStorage(STORAGE_KEYS.NETWORK);

  const [chainId] = useState(chainIDFromStorage || null);
  const chainDetails = supportedChains.find(
    (chain) => chain.chainId === chainId,
  )
    ? supportedChains.find((chain) => chain.chainId === chainId)
    : supportedChains.find(
        (chain) => chain.chainId === Config.DEFAULT_NETWORK.CHAIN_ID,
      );

  const storeData: any = Store.getState();

  const [transactionGas, setTransactionGas] = useState<SelectedTokenForGasType>(
    chainId
      ? initialSelectedTokenForGas(chainId)
      : SelectedTokenForGasDefault(chainId),
  );
  const [nativeAssetGas, setNativeAssetGas] = useState<string>("loading");
  const [developerMode, setDeveloperMode] = useState(false);

  // Progress bar logic
  const [progress, setProgress] = useState<number>(0);
  const [fillStarted, setFillStarted] = useState<boolean>(false);

  const [adURL, setAdURL] = useState<string | null>(null);

  const {
    isVerifyPassModalOpen,
    verifyPassword,
    isVerifying,
    showPasswordError,
  } = usePasswordVerification();

  const getActiveAddress = () =>
    userSettings?.isEoaSelected ? eoaAddress : smartAccountAddress;

  useEffect(() => {
    async function getGasInUSD() {
      if (nativeAssetGas !== "loading" && chainId === 1) {
        setIsLoading(true);
        const resp = await getUSDValue({
          cryptoAsset: "ETH",
          fiatCurrency: "USD",
        });

        setNativeAssetInUSD(resp);
        setIsLoading(false);
      }
    }

    getGasInUSD();
  }, [nativeAssetGas]);

  useEffect(() => {
    if (nativeAssetInUSD && nativeAssetGas !== "loading") {
      setGasInUSD(Number(nativeAssetGas) * nativeAssetInUSD);
    }
  }, [nativeAssetInUSD, nativeAssetGas]);

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const usersSettings: UserSettingsType[] = await getUserSettingsData();

        const userIndex = usersSettings.findIndex(
          (user: UserSettingsType) => user.address === eoaAddress,
        );

        if (userIndex !== -1) {
          setActiveSmartWallet(usersSettings[userIndex]);

          const { turnOffAds } =
            usersSettings[userIndex].settings.developerMode;

          setDeveloperMode(turnOffAds);

          const { biometrics } = usersSettings[userIndex].settings.security;

          const { approveTransaction } =
            usersSettings[userIndex].settings.transactionSettings;

          setWalletAuthType(approveTransaction);
          setCredId(biometrics || "");
        } else {
          log("User not found in local storage");
        }
      } catch (error) {
        log("Error fetching user settings:", error);
      }
    };

    fetchUserSettings();
  }, [walletAuthType, eoaAddress]);

  useEffect(() => {
    if (fillStarted) {
      const startTime = Date.now();

      const fillProgressBar = () => {
        const elapsedTime = Date.now() - startTime;

        if (elapsedTime >= 10000) {
          setProgress(90);
        } else {
          const percentage = (elapsedTime / 10000) * 90;

          setProgress(percentage);
          setTimeout(fillProgressBar, 50);
        }
      };

      fillProgressBar();
    }
  }, [fillStarted]);

  const handleReject = (reason: string | null = null) => {
    EthProvider.onCancel(reason);
  };

  const handleError = () => {
    handleReject(ERROR_MESSAGE.DAPP_NETWORK_ERROR);
  };

  useEffect(() => {
    window.addEventListener(ERROR_EVENTS.SMART_WALLET_ERROR, handleError);
    return () => {
      window.addEventListener(ERROR_EVENTS.SMART_WALLET_ERROR, handleError);
    };
  });

  useEffect(() => {
    async function initializeSmartWallet() {
      if (!smartAccountAddress) {
        init({
          walletName: activeSmartWallet?.walletName || "",
          chainId,
        });
      }
    }

    setSmartWalletAddress(smartAccountAddress);

    initializeSmartWallet();
    getNativeAssetGas(partialTransaction, setNativeAssetGas);

    // if (provider && smartAccountAddress) setIsLoading(false);
  }, [smartAccountAddress, smartWalletAddress]);

  const sendEoaTransaction = async (
    finalUserOp: Partial<UserOperationStruct> & { value?: any },
    _isGasless: GAS_FEE_TYPE,
  ) => {
    const signer = getEoaSigner(eoaPrivateKey, provider);

    finalUserOp.value = BigNumber.from(finalUserOp?.value);

    const tx = await signer.sendTransaction(finalUserOp);
    const transactionDetails = await tx.wait();
    const { from } = transactionDetails;
    const { from: sender, status, ...rest } = transactionDetails;
    const updatedTransactionDetails = {
      sender,
      success: status === 1 ? "true" : "false",
      ...rest,
    };
    const currentTimestamp: number = Math.floor(Date.now() / 1000);

    const explorerUrl: string = getExplorerTxUrl(chainId);

    const currentAmount: number = transactionData.value ?? 0;
    const formattedAmount: string = ethers.utils.formatEther(currentAmount);

    const txnData: TransactionData = {
      date: formatDateFromTimestamp(currentTimestamp),
      time: formatUnixTimestamp(currentTimestamp),
      transaction_id: transactionDetails.transactionHash,
      from_address: from,
      to_address: transactionData.to,
      nonce: tx.nonce.toString(),
      amount: formattedAmount.toString(),
      gas_used: transactionDetails.gasUsed.toString(),
      label:
        transactionData.data === "" || transactionData.data === "0x"
          ? `Send`
          : "Contract Interaction",
      status: transactionDetails.status ? "1" : "0",
      explorer: explorerUrl,
      isGasless: _isGasless,
    };

    // eslint-disable-next-line no-await-in-loop
    await setTransactionDataInLocal(from || eoaAddress, chainId, txnData);

    setProgress(100);
    setFillStarted(false);

    MixPanel.track(MIXPANEL_KEY.Transaction_Completed, {
      transactionId,
      gasFeeType: _isGasless,
      gasFeeAsset:
        _isGasless === GAS_FEE_TYPE.GASLESS
          ? null
          : {
              name: transactionGas.tokenName,
              contractAddress: transactionGas.tokenAddress,
              value: transactionGas.tokenGasValue,
            },
      chainId,
      status: TRANSACTION_STATUS.TRANSACTION_SUCCESS,
      transactionHash: transactionDetails.transactionHash,
      transactionReceipt: updatedTransactionDetails,
      transactions: transactionData,
    });

    const data = {
      eoaAddress: eoaAddress.toLowerCase(),
      smartAccountAddress: transactionDetails.from.toLowerCase(),
      chainId,
      isMainnet: checkIsMainnet(chainId),
      isBatch: false,
      transactionId,
      transactionHash: transactionDetails.transactionHash,
      status: TRANSACTION_STATUS.TRANSACTION_SUCCESS,
      gasFeeType: _isGasless,
      receipt: updatedTransactionDetails,
      transactions: [transactionData],
    };

    saveCompletedTxn({
      data,
    });

    EthProvider.onAccept(transactionDetails);
  };

  const sendTransaction = async (
    finalUserOp: Partial<UserOperationStruct>,
    _isGasless: GAS_FEE_TYPE,
  ) => {
    const smartAccountProvider: BiconomySmartAccountV2 | null =
      await getSmartAccountProvider();

    if (!smartAccountProvider) throw new Error("Provider not found");

    const userOpResponse = await smartAccountProvider.sendUserOp(finalUserOp);
    const transactionDetails = await userOpResponse.wait();
    const currentTimestamp: number = Math.floor(Date.now() / 1000);

    const explorerUrl: string = getExplorerTxUrl(chainId);

    const currentAmount: number = transactionData.value ?? 0;
    const formattedAmount: string = ethers.utils.formatEther(currentAmount);

    const txnData: TransactionData = {
      date: formatDateFromTimestamp(currentTimestamp),
      time: formatUnixTimestamp(currentTimestamp),
      transaction_id: transactionDetails.receipt.transactionHash,
      from_address: smartAccountAddress,
      to_address: transactionData.to,
      nonce: `${finalUserOp.nonce}`,
      amount: formattedAmount,
      gas_used: transactionDetails.actualGasUsed,
      label:
        transactionData.data === "" || transactionData.data === "0x"
          ? `Send`
          : "Contract Interaction",
      status: transactionDetails.success ? "1" : "0",
      explorer: explorerUrl,
      isGasless: _isGasless,
    };

    await setTransactionDataInLocal(smartAccountAddress, chainId, txnData);

    setProgress(100);
    setFillStarted(false);

    MixPanel.track(MIXPANEL_KEY.Dapp_Transaction_Completed, {
      transactionId,
      gasFeeType: _isGasless,
      gasFeeAsset:
        _isGasless === GAS_FEE_TYPE.GASLESS
          ? null
          : {
              name: transactionGas.tokenName,
              contractAddress: transactionGas.tokenAddress,
              value: transactionGas.tokenGasValue,
            },
      chainId,
      status: TRANSACTION_STATUS.TRANSACTION_SUCCESS,
      transactionHash: transactionDetails.receipt.transactionHash,
      transactionReceipt: transactionDetails,
      transactions: transactionData,
    });

    const data = {
      eoaAddress: eoaAddress.toLowerCase(),
      smartAccountAddress: smartAccountAddress.toLowerCase(),
      chainId,
      isMainnet: checkIsMainnet(chainId),
      isBatch: false,
      transactionHash: transactionDetails.receipt.transactionHash,
      status: TRANSACTION_STATUS.TRANSACTION_SUCCESS,
      gasFeeType: _isGasless,
      transactionId,
      receipt: transactionDetails,
      transactions: [transactionData],
    };

    saveCompletedTxn({
      data,
    });

    EthProvider.onAccept(transactionDetails);
  };

  const sendGaslessTransaction = async (
    gaslessTransactionOp: Partial<UserOperationStruct>,
  ) => {
    try {
      MixPanel.track(MIXPANEL_KEY.Dapp_Transaction_Broadcasted, {
        transactionId,
        gasFeeType: GAS_FEE_TYPE.GASLESS,
        gasFeeAsset: null,
        chainId,
        transactions: transactionData,
      });

      let isValid = false;

      if (walletAuthType === DEVICE_AUTH_TYPE.BOTH) {
        isValid = await biometricAuth(credId);
      } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSKEY) {
        isValid = await biometricAuth(credId);
      } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSWORD) {
        isValid = await verifyPassword();
        return;
      } else if (walletAuthType === DEVICE_AUTH_TYPE.NONE) {
        isValid = true;
      }

      if (!isValid) {
        handleReject("Authentication Failed");
        return;
      }

      setTransactionInProcess(true);
      setFillStarted(true);

      await sendTransaction(gaslessTransactionOp, GAS_FEE_TYPE.GASLESS);
    } catch (e: any) {
      MixPanel.track(MIXPANEL_KEY.Dapp_Transaction_Completed, {
        transactionId,
        gasFeeType: GAS_FEE_TYPE.GASLESS,
        gasFeeAsset: null,
        chainId,
        status: TRANSACTION_STATUS.TRANSACTION_FAILED,
        transactions: transactionData,
      });

      log("Error while sending gasless txn in Dapp Interaction : ", e, "error");

      const data = {
        eoaAddress: eoaAddress.toLowerCase(),
        smartAccountAddress: smartAccountAddress.toLowerCase(),
        chainId,
        isMainnet: checkIsMainnet(chainId),
        isBatch: false,
        status: TRANSACTION_STATUS.TRANSACTION_FAILED,
        gasFeeType: GAS_FEE_TYPE.GASLESS,
        transactionId,
        adURL,
        transactions: [transactionData],
      };

      saveCompletedTxn({
        data,
      });

      setProgress(0);
      setFillStarted(false);
      EthProvider.onCancel(`Error while sending gasless txn: ${e.message}`);
    }
  };

  const sendBatchTransaction = async (isReject?: boolean) => {
    if (isReject) {
      handleReject(null);
      return;
    }

    try {
      MixPanel.track(MIXPANEL_KEY.Dapp_Transaction_Broadcasted, {
        transactionId,
        gasFeeType: checkIsNativeToken(transactionGas.tokenAddress)
          ? GAS_FEE_TYPE.COIN
          : GAS_FEE_TYPE.TOKEN,
        gasFeeAsset: {
          name: transactionGas.tokenName,
          contractAddress: transactionGas.tokenAddress,
          value: transactionGas.tokenGasValue,
        },
        chainId,
        transactions: transactionData,
      });

      let isValid = false;

      if (walletAuthType === DEVICE_AUTH_TYPE.BOTH) {
        isValid = await biometricAuth(credId);
      } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSKEY) {
        isValid = await biometricAuth(credId);
      } else if (walletAuthType === DEVICE_AUTH_TYPE.PASSWORD) {
        isValid = await verifyPassword();
        return;
      } else if (walletAuthType === DEVICE_AUTH_TYPE.NONE) {
        isValid = true;
      }

      if (!isValid) {
        handleReject("Authentication Failed");
        return;
      }

      setTransactionInProcess(true);
      setFillStarted(true);

      let finalUserOp: Partial<UserOperationStruct> = partialTransaction;

      if (userSettings?.isEoaSelected) {
        await sendEoaTransaction(finalUserOp, GAS_FEE_TYPE.COIN);
      } else {
        const smartAccountProvider: BiconomySmartAccountV2 | null =
          await getSmartAccountProvider();

        if (!smartAccountProvider)
          throw new Error("Unable to initialize SC provider");

        if (!checkIsNativeToken(transactionGas.tokenAddress)) {
          finalUserOp = await constructFinalUserOp(
            smartAccountProvider,
            finalUserOp,
            transactionGas.tokenAddress,
          );
        }

        await sendTransaction(
          finalUserOp,
          checkIsNativeToken(transactionGas.tokenAddress)
            ? GAS_FEE_TYPE.COIN
            : GAS_FEE_TYPE.TOKEN,
        );
      }
    } catch (e: any) {
      MixPanel.track(MIXPANEL_KEY.Dapp_Transaction_Completed, {
        transactionId,
        gasFeeType: checkIsNativeToken(transactionGas.tokenAddress)
          ? GAS_FEE_TYPE.COIN
          : GAS_FEE_TYPE.TOKEN,
        gasFeeAsset: {
          name: transactionGas.tokenName,
          contractAddress: transactionGas.tokenAddress,
          value: transactionGas.tokenGasValue,
        },
        chainId,
        status: TRANSACTION_STATUS.TRANSACTION_FAILED,
        transactions: transactionData,
      });

      log("Error while sending txn in Dapp Interaction : ", e, "error");

      const data = {
        eoaAddress: eoaAddress.toLowerCase(),
        smartAccountAddress: getActiveAddress().toLowerCase(),
        chainId,
        isMainnet: checkIsMainnet(chainId),
        isBatch: false,
        status: TRANSACTION_STATUS.TRANSACTION_FAILED,
        gasFeeType: checkIsNativeToken(transactionGas.tokenAddress)
          ? GAS_FEE_TYPE.COIN
          : GAS_FEE_TYPE.TOKEN,
        transactionId,
        transactions: [transactionData],
      };

      saveCompletedTxn({
        data,
      });

      setProgress(0);
      setFillStarted(false);
      EthProvider.onCancel(`Error while sending txn: ${e.message}`);
    }
  };

  const buildPartialTransaction = async (transferData: any) => {
    const address = getActiveAddress();

    if (!isAddressSame(transferData?.from, address)) {
      handleReject("Not the correct signer address");
      return;
    }

    const transactions: any[] = [];
    const obj: any = {};

    obj.to = transferData.to;
    obj.value = transferData.value || "0";
    obj.from = transferData.from;
    obj.data = transferData.data || "0x";

    transactions.push(obj);
    const smartAccountProvider: BiconomySmartAccountV2 | null =
      await getSmartAccountProvider();

    if (!smartAccountProvider)
      throw new Error("Unable to initialize SC provider.");

    const partialUserOp: Partial<UserOperationStruct> =
      await smartAccountProvider.buildUserOp(transactions);

    setPartialTransaction(partialUserOp);
    getNativeAssetGas(partialUserOp, setNativeAssetGas);
  };

  const buildEoaPartialTransaction = async (transferData: any) => {
    const address = getActiveAddress();

    if (!isAddressSame(transferData?.from, address)) {
      handleReject("Not the correct signer address");
      return;
    }

    const txn: any = {};

    txn.to = transferData.to;
    txn.value = transferData.value || "0";
    txn.from = transferData.from;
    txn.data = transferData.data || "0x";

    const partialUserOp: Partial<UserOperationStruct> = txn;

    setPartialTransaction(partialUserOp);
    getNativeAssetGasForEoa(provider, partialUserOp, setNativeAssetGas);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const shouldShowAd = () => {
    if (userSettings?.isEoaSelected) return false;

    if (chainDetails && !chainDetails?.isMainnet)
      if (developerMode) return false;

    return true;
  };

  async function buildAndSetPartialData() {
    if (
      transactionData &&
      // transactionData.data &&
      transactionData.from &&
      transactionData.to
      // transactionData.value
    ) {
      try {
        if (userSettings?.isEoaSelected) {
          buildEoaPartialTransaction(transactionData);
        } else {
          await buildPartialTransaction(transactionData);
        }
      } catch (e: any) {
        log(
          "Error while building partial txn : ",
          { e, msg: e?.message },
          "error",
        );
        EthProvider.onCancel(`Error while building partial txn: ${e.message}`);
      }
    }
  }

  useEffect(() => {
    const id = uuidv4();

    setTransactionId(id);
    if (partialTransaction.sender === "") buildAndSetPartialData();

    MixPanel.track(MIXPANEL_KEY.Dapp_Transaction_Initiated, {
      transactionId: id,
      chainId,
      transactions: transactionData,
    });
  }, [
    transactionData.data,
    transactionData.from,
    transactionData.to,
    transactionData.value,
    partialTransaction,
    isInitialized,
  ]);

  useEffect(() => {
    setIsLoading(true);
    if (
      isInitialized && // a error might occur
      partialTransaction.sender !== "" &&
      provider &&
      smartAccountAddress
    ) {
      setIsLoading(false);
    }
  }, [
    partialTransaction,
    partialTransaction.sender,
    isInitialized,
    smartAccountAddress,
    provider,
  ]);

  useEffect(() => {
    const data: any = Store.getTempState();

    if (data) {
      setTransactionData({
        ...data.transactionData,
      });
    }
  }, [
    transactionData.data,
    transactionData.from,
    transactionData.to,
    transactionData.value,
  ]);

  return (
    <TransactionReview
      isLoading={isLoading}
      smartWalletAddress={getActiveAddress()}
      transactionData={transactionData}
      storeData={storeData}
      chainDetails={chainDetails}
      handleReject={handleReject}
      transactionId={transactionId}
      transactionInProcess={transactionInProcess}
      progress={progress}
      transactionGas={transactionGas}
      setTransactionGas={setTransactionGas}
      partialTransaction={partialTransaction}
      sendGaslessTransaction={sendGaslessTransaction}
      sendBatchTransaction={sendBatchTransaction}
      nativeAssetGas={nativeAssetGas}
      shouldShowAd={shouldShowAd}
      gasInUSD={gasInUSD}
      isVerifyPassModalOpen={isVerifyPassModalOpen}
      isVerifying={isVerifying}
      showPasswordError={showPasswordError}
      setAdURL={setAdURL}
    />
  );
}
