import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import angle from "../../assets/angleRight.svg";

import logo from "../../assets/logo/walletX-Full.png";

import * as Config from "../../config/env";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";
import { getActiveUserDevice } from "../../utils/helper";

const GaslessDashboard = () => {
  const { eoaAddress } = useWalletConfig();

  const navigateTo = useNavigate();
  const [walletName, setWalletName] = useState<string>("");

  const getWalletName = async () => {
    const userSettings: any = await getActiveUserDevice(eoaAddress);

    setWalletName(userSettings.walletName);
    return userSettings.walletName;
  };

  const generateStars = (numStars: number) => {
    const stars = [];

    for (let i = 0; i < numStars; i += 1) {
      const style = {
        // eslint-disable-next-line security-node/detect-insecure-randomness
        top: `${Math.random() * 100}vh`,

        // eslint-disable-next-line security-node/detect-insecure-randomness
        left: `${Math.random() * 100}vw`,
        width: "1px",
        height: "1px",
        backgroundColor: "white",
        opacity: "40%",
      };

      stars.push(
        <div key={i} className="absolute rounded-full" style={style}></div>,
      );
    }
    return stars;
  };

  const stars = useMemo(() => generateStars(1000), []);

  useEffect(() => {
    getWalletName();
  }, []);

  return (
    <>
      <div className="h-full w-full flex flex-col justify-center items-center relative select-none bg-black">
        <div className="w-full p-2 px-4 flex justify-between items-center relative">
          <div
            onClick={() => navigateTo("/")}
            className="flex p-1 px-2 gap-2 items-center text-xs border border-[#ACACD7] border-opacity-50  rounded-full h-fit w-fit relative z-10"
          >
            <img src={angle} className="h-4 w-4 rotate-180 " />
            Back to WalletX
          </div>

          <div className="flex gap-1 items-center text-xs">
            <img src={logo} className="h-6  " />
            <p className="text-xs bg-gradient-to-r from-[#976EFF] to-[#8BA8FF] bg-clip-text text-transparent">
              Mineboard
            </p>
          </div>
          <div className="absolute top-0 left-0 w-full h-full">{stars}</div>
        </div>
        <div className="h-full w-full relative z-10">
          <iframe
            src={`${Config.USER_DASHBOARD_URL}onboarding?eoaAddress=${eoaAddress}&walletName=${walletName}`}
            allow="accelerometer; gyroscope; vibrate; clipboard-write"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            className="rounded-t-3xl"
            title="User Dashboard"
          />
        </div>
      </div>
    </>
  );
};

export default GaslessDashboard;
