import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { X } from "react-feather";
import {
  AmountAndBalanceType,
  AssetUidAndAmountType,
  TransferStateType,
  TokenAssetCardType,
  SwapBridgeStateType,
} from "../../constants/Types";
import { supportedChainsState } from "../../state/SupportedChainsState";
import { transferState } from "../../state/TransferState";
import { ASSET_SELECTION_TASK, ASSET_TYPE } from "../../constants/Enums";
import amountAndBalanceState from "../../state/AmountAndBalanceState";
import { swapBridgeState } from "../../state/SwapBridgeState";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

const TokenAssetCard = ({
  assetCardSelectionTask,
  token,
  transactionUID,
  assetUID,
  openAssetSelectionDrawer,
  openRemoveAssetModal,
  isProceedEnable,
  isReview,
}: TokenAssetCardType) => {
  const [chains] = useRecoilState(supportedChainsState);
  const { chainID } = useWalletConfig();

  const { isGaslessEnabled } = chains.filter((d) => d.chainId === chainID)[0];

  const [transferData, setTransferData] = useRecoilState(transferState);
  const [swapBridgeData, setSwapBridgeData] = useRecoilState(swapBridgeState);
  const [amountAndBalanceData, setAmountAndBalanceData] = useRecoilState(
    amountAndBalanceState,
  );
  const [amountIsInvalid, setAmountIsInvalid] = useState<boolean>(false);
  const location = useLocation();
  const currentLocation = location.pathname;
  const [tokenAmount, setTokenAmount] = useState<number | undefined>(
    token.amount,
  );
  const [defaultKey, setDefaultKey] = useState(token.address);

  const updateAmountAndBalance = (
    _enteredAmount: number,
  ): AmountAndBalanceType[] => {
    const enteredAmount = Number(_enteredAmount);
    const tokenBalanceAndAmount: AmountAndBalanceType[] =
      amountAndBalanceData || [];

    const newTokenBalanceAmount: AmountAndBalanceType[] = [];

    if (tokenBalanceAndAmount.length) {
      let tokenBalanceAndAmountData: AmountAndBalanceType | undefined;

      tokenBalanceAndAmount.forEach((tokenData: AmountAndBalanceType) => {
        if (
          tokenData.tokenAddress.toLowerCase() === token.address.toLowerCase()
        ) {
          tokenBalanceAndAmountData = tokenData;
        } else {
          newTokenBalanceAmount.push(tokenData);
        }
      });

      if (
        tokenBalanceAndAmountData &&
        Object.keys(tokenBalanceAndAmountData).length
      ) {
        const assetUidArray: AssetUidAndAmountType[] = [];

        tokenBalanceAndAmountData.assetUidAndAmount.forEach(
          (assetData: AssetUidAndAmountType) => {
            if (assetData.assetUid.toLowerCase() === assetUID.toLowerCase()) {
              assetUidArray.push({
                assetUid: assetUID,
                amount: enteredAmount,
              });
            } else {
              assetUidArray.push(assetData);
            }
          },
        );

        if (
          !assetUidArray.find(
            (assetData: AssetUidAndAmountType) =>
              assetData.assetUid.toLowerCase() === assetUID.toLowerCase(),
          )
        ) {
          assetUidArray.push({
            assetUid: assetUID,
            amount: enteredAmount,
          });
        }

        let amount = 0;

        assetUidArray.forEach((data: AssetUidAndAmountType) => {
          amount += data.amount;
        });

        newTokenBalanceAmount.push({
          assetUidAndAmount: assetUidArray,
          tokenAddress: tokenBalanceAndAmountData.tokenAddress,
          initialBalance: tokenBalanceAndAmountData.initialBalance,
          remainingBalance:
            Number(tokenBalanceAndAmountData.initialBalance) - Number(amount),
        });
      }
      // if tokenAddress (same token) is not available inside data
      else {
        const newTokenBalance: AmountAndBalanceType = {
          assetUidAndAmount: [
            {
              assetUid: assetUID,
              amount: enteredAmount,
            },
          ],
          tokenAddress: token.address,
          initialBalance: token.balance,
          remainingBalance: token.balance - enteredAmount,
        };

        newTokenBalanceAmount.push(newTokenBalance);
      }
    } else {
      const newTokenBalance: AmountAndBalanceType = {
        assetUidAndAmount: [
          {
            assetUid: assetUID,
            amount: enteredAmount,
          },
        ],
        tokenAddress: token.address,
        initialBalance: token.balance,
        remainingBalance: token.balance - enteredAmount,
      };

      newTokenBalanceAmount.push(newTokenBalance);
    }

    setAmountAndBalanceData(newTokenBalanceAmount);
    return newTokenBalanceAmount;
  };

  const amountValidationCheck = (_enteredAmount: number): boolean => {
    const data: AmountAndBalanceType[] = updateAmountAndBalance(_enteredAmount);

    const checkValid: boolean[] = [];

    data.forEach((d) => {
      if (d.remainingBalance >= 0) {
        checkValid.push(true);
      } else {
        checkValid.push(false);
      }
    });

    return !checkValid.filter((isValid) => !isValid).length;
  };

  const updateAmount = (enteredAmount: number) => {
    if (assetCardSelectionTask === ASSET_SELECTION_TASK.TRANSACTION) {
      const updatedAmount = transferData.map(
        (transferDetail: TransferStateType) => {
          const data: TransferStateType = { ...transferDetail };

          if (transferDetail.uid === transactionUID && data.assets) {
            const updatedAssets = data.assets.map((asset) => {
              if (
                asset.assetUID === assetUID &&
                asset.assetType === ASSET_TYPE.TOKEN
              ) {
                const { tokenDetails } = asset;

                if (tokenDetails) {
                  setTokenAmount(enteredAmount);
                  return {
                    ...asset,
                    tokenDetails: {
                      ...tokenDetails,
                      amount: enteredAmount,
                    },
                  };
                }
              }

              return asset;
            });

            data.assets = updatedAssets;
          }

          return data;
        },
      );

      setTransferData(updatedAmount);
    } else {
      const updatedAmount = swapBridgeData.map(
        (transferDetail: SwapBridgeStateType) => {
          const data: SwapBridgeStateType = { ...transferDetail };

          if (
            transferDetail.uid === transactionUID &&
            (data.fromAsset || data.toAsset)
          ) {
            if (assetUID === data.fromAsset?.assetUID) {
              const { tokenDetails } = data.fromAsset;

              if (tokenDetails) {
                setTokenAmount(enteredAmount);

                data.fromAsset = {
                  ...data.fromAsset,
                  tokenDetails: {
                    ...tokenDetails,
                    amount: enteredAmount,
                  },
                };
              }
            } else if (assetUID === data.toAsset?.assetUID) {
              const { tokenDetails } = data.toAsset;

              if (tokenDetails) {
                setTokenAmount(enteredAmount);

                data.toAsset = {
                  ...data.toAsset,
                  tokenDetails: {
                    ...tokenDetails,
                    amount: enteredAmount,
                  },
                };
              }
            }
          }

          return data;
        },
      );

      setSwapBridgeData(updatedAmount);
    }
  };

  const handleAmountChange = (e: any) => {
    const enteredAmount = parseFloat(e.target.value);

    if (amountValidationCheck(enteredAmount)) {
      updateAmount(enteredAmount);
      setAmountIsInvalid(false);
      isProceedEnable(true);
    } else {
      setAmountIsInvalid(true);
      isProceedEnable(false);
    }
  };

  useEffect(() => {
    if (token.amount === 0) {
      setTokenAmount(0);
    }

    setDefaultKey(token.address);
  }, [token]);

  return (
    <>
      <div className="group flex relative flex-col gap-2 justify-center items-center w-full py-1 ">
        <div
          className={` ${amountIsInvalid === true ? "invalid__shake" : ""} ${
            assetCardSelectionTask === ASSET_SELECTION_TASK.TRANSACTION
              ? " w-[95%] "
              : " w-full "
          } flex place-self-end bg-card-bg2 border  border-black py-1  gap-2 rounded-lg px-2   h-12  justify-between  items-center z-20 `}
        >
          <div
            onClick={() => {
              if (
                currentLocation !==
                "/dashboard/transaction/approve-transactions"
              ) {
                openAssetSelectionDrawer();
              }
            }}
            className="flex justify-center items-center gap-2 hover:cursor-pointer"
          >
            <img
              className="h-8  object-scale-down rounded-full  "
              src={token.logo}
              alt="tokenIcon"
            />

            <div className="flex flex-col items-start z-10 ">
              <div className=" flex flex-col ">
                <div className="flex justify-start items-center gap-1">
                  <p className="text-sm place-self-center text-center font-semibold">
                    {token.symbol}
                  </p>
                </div>
                <p className="text-xs ">
                  Balance: <span>{Number(token?.balance).toFixed(5)}</span>
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-end text-sm  px-1">
            <div className=" flex gap-2 ">
              <input
                disabled={
                  currentLocation ===
                  "/dashboard/transaction/approve-transactions"
                }
                className="bg-transparent  border-black outline-none max-w-[50px] text-right "
                type="number"
                min={0}
                placeholder="0"
                defaultValue={`${tokenAmount === 0 ? "" : tokenAmount}`}
                key={defaultKey}
                onChange={(e) => {
                  handleAmountChange(e);
                }}
              />
            </div>
            {!isReview && (
              <p
                className="cursor-pointer hover:underline text-xs"
                onClick={() => {
                  if (isGaslessEnabled) {
                    handleAmountChange({
                      target: { value: Number(token?.balance) },
                    });
                  } else {
                    handleAmountChange({
                      target: { value: Number(token?.balance) * 0.9 },
                    });
                  }
                }}
              >
                Max
              </p>
            )}
          </div>
        </div>

        {currentLocation !== "/dashboard/transaction/approve-transactions" && (
          <button
            onClick={() => {
              openRemoveAssetModal();
            }}
            className="absolute group top-[-0.15em] right-[-0.1em] bg-neutral-500 border border-black hover:bg-[#b91c1c] font-semibold text-sm -z-10 group-hover:z-20  rounded-full "
          >
            <X size={11} color="black" />
          </button>
        )}

        {assetCardSelectionTask === ASSET_SELECTION_TASK.TRANSACTION && (
          <div className="absolute w-4 h-20 border-l-2 border-b rounded-bl-xl border-neutral-700 top-[-2.75em] left-[0.25em] z-10 "></div>
        )}
      </div>
    </>
  );
};

export default TokenAssetCard;
