import { useState } from "react";
import { motion } from "framer-motion";
import { Plus } from "react-feather";
import TokenCardTransaction from "../TokenCardTransaction";
import useAssets from "../../lib/store/hooks/useAssets";
import { TokenListType } from "../../constants/Types";
import ImportToken from "../Modals/ImportToken";
import IllustrationLoader from "../IllustrationLoader";

const TokenList = ({ isImportTokenDrawerAvailable }: TokenListType) => {
  const { tokens, loading, error } = useAssets();
  const [isImportTokenDrawerOpen, setIsImportTokenDrawerOpen] = useState(false);

  const renderSortedTokensByBalance = () => {
    if (loading) {
      return (
        <div className="w-fit mx-auto">
          <IllustrationLoader height="1.4em" />
        </div>
      );
    }

    return tokens.map((token, index) => (
      <motion.div
        layout
        key={token.address}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <TokenCardTransaction
          tokenIcon={token.logo}
          tokenName={token.name}
          tokenSymbol={token.symbol}
          tokenAddress={token.address}
          tokenDecimal={token.decimal}
          userAddress={token.address || ""}
          clickedTokenData={() => null}
          key={`default-${index}`}
          tokenBalance={token.balance || 0}
        />
      </motion.div>
    ));
  };

  const openImportTokenModal = () => {
    setIsImportTokenDrawerOpen(true);
  };
  const closeImportTokenDrawer = () => {
    setIsImportTokenDrawerOpen(false);
  };

  return (
    <>
      <div className="h-full pb-9 select-none">
        {/* // TODO update the error message here and also stop the import type */}
        {error ? <div>{error}</div> : renderSortedTokensByBalance()}
        {isImportTokenDrawerAvailable && (
          <>
            {/* This empty div block is used to manage the UI for import token buttons in case no tokens are there. */}
            {!tokens.length && (
              <div className="flex gap-1 flex-row items-center px-4 pt-3 pb-4 rounded-lg  "></div>
            )}
            <div className="flex flex-col gap-2 my-5 mb-10 pb-6">
              <button
                onClick={openImportTokenModal}
                className="flex gap-1 justify-center items-center"
              >
                <Plus />
                <p className=" text-sm font-semibold">Import Tokens</p>
              </button>
            </div>
          </>
        )}
      </div>

      <ImportToken
        isOpen={isImportTokenDrawerOpen}
        onClose={closeImportTokenDrawer}
      />
    </>
  );
};

export default TokenList;
