import { useEffect } from "react";
import toast from "react-hot-toast";

import { getShortDisplayString, isChainSupported } from "../../../utils/helper";
import eye from "../../../assets/eye.svg";

type ConnectScreenProps = {
  connectOrigin: string | null;
  smartWalletAddress: string | null;
  rejectConnectionRequest: (reason?: string) => void;
  connectWallet: () => void;
  connectOriginIcon?: string | null;
  activeChain?: Array<string>;
};

export default function ConnectScreen({
  connectOrigin,
  smartWalletAddress,
  rejectConnectionRequest,
  connectWallet,
  connectOriginIcon,
  activeChain,
}: ConnectScreenProps) {
  useEffect(() => {
    if (activeChain) {
      const isSupported = isChainSupported(activeChain);

      if (!isSupported) {
        toast.error("Unsupported Chain");
        rejectConnectionRequest("Unsupported Chain");
      }
    }
  }, [activeChain]);

  return (
    <div className="p-2 mx-auto w-[341px] h-[600px] text-white  relative top-1/2 translate-y-[-50%]">
      <p className="flex gap-3 p-2 px-4 w-fit max-w-full  mx-auto mt-4 text-[18px] truncate text-slate-200 border border-slate-500 rounded-full ">
        {connectOriginIcon && (
          <img alt="" src={connectOriginIcon} height="30px" width="30px" />
        )}
        {connectOrigin}
      </p>
      <p className="text-center text-lg font-bold mt-4">Connect to</p>
      <p className="text-center ">
        {getShortDisplayString(smartWalletAddress)}
      </p>
      <p className="text-center text-md font-semibold mt-6">
        Allow this site to:
      </p>
      <div className="mt-8 pb-6 flex item-center gap-2 border-b border-slate-500">
        <img
          src={eye}
          alt="eye"
          className="h-8 p-2 bg-blue-500 bg-opacity-10 rounded-full"
        />
        <p className="text-slate-300">
          See address, account balance, activity and suggest transactions to
          approve
        </p>
      </div>
      <div className=" absolute left-0 bottom-0 w-full  ">
        <p className=" mb-2 text-center text-sm text-slate-300">
          Only connect with sites you trust.
        </p>
        <div className="py-4 border-t border-gray-500  flex justify-around gap-2">
          <button
            className="w-[46%] p-2 px-4 shadow-lg rounded-lg border hover:border-gray-500"
            onClick={() => rejectConnectionRequest()}
          >
            Cancel
          </button>
          <button
            className="w-[46%] p-2 px-4 bg-gray-300 hover:bg-gray-500 text-black shadow-lg rounded-lg"
            onClick={connectWallet}
          >
            Connect
          </button>
        </div>
      </div>
    </div>
  );
}
