import React, { useEffect, useRef } from "react";
import createWeb3Avatar from "web3-avatar";
import { motion } from "framer-motion";

interface Web3AvatarComponentProps {
  address: string;
  className?: string;
  style?: object;
  initial?: object;
  animate?: object;
  exit?: object;
  transition?: object;
  layout?: boolean;
  onClickAvatar?: (e: any) => any;
}
const Web3AvatarComponent: React.FC<Web3AvatarComponentProps> = ({
  address,
  className,
  style,
  initial,
  animate,
  exit,
  transition,
  layout,
  onClickAvatar,
}) => {
  const avatarRef = useRef(null);

  useEffect(() => {
    if (avatarRef.current && address) {
      createWeb3Avatar(avatarRef.current, address.toLowerCase());
    }
  }, [address]);

  return (
    <motion.div
      layout={layout}
      ref={avatarRef}
      className={className}
      style={style}
      onClick={onClickAvatar}
      initial={initial}
      animate={animate}
      exit={exit}
      transition={transition}
    ></motion.div>
  );
};

export default Web3AvatarComponent;
